@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Yellowtail&display=swap);
/*
    Writing media querys as mixins makes you write less code, and if you have to change breakpoints
    it's bette to change only one mixin than all of your files
*/
/*

0 - 600px => Phone

600px - 900px => Tablet portrait

900px - 1200px => Tablet landscape

1200px - 1800px => NORMAL STYLES

1800 + => Big screens

*/
/*
    $breakpoint argument choices:

    - phone
    - tab-port
    - tab-land
    - big-desktop

*/
/*
    OBS:

    The max-width 600px query applies to all devices tha have width inferior to 600px,
    but The max-width 900px query applies to all devices tha have width inferior to 900px,
    INCLUDING THE 600px OR LESS DEVICE.

    THATS WHY YOU HAVE TO BE CAREFUL WITH THE ORDER OF MEDIA QUERIES:

    The first one should be the larger and the last the smaller, in desktop first applications,
    and the other way around in mobile first applications

*/
/*
    In media queries, we should use a unit that is affected by changes in the default font-size.
    It's better for responsive purposes.

    In media queries both rem and em are not affect by the font-size set in the html tag, but by
    the font-size of the browser, so they are the same.

    As rems fail to work in some browsers, the best option is to use ems to define media queries

    1em = 16px (default font size of the browser)

*/
/*
    SUGESTION OF ORDER TO IMPLEMENT MEDIA QUERIES:

    Base + Typography > General Layout + Grid > Page Layout > Components
*/
@-webkit-keyframes loadingBall {
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }
@keyframes loadingBall {
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

html {
  font-size: 62.5%; }
  @media (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media (min-width: 112.5em) {
    html {
      font-size: 75%; } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent; }

body {
  box-sizing: border-box; }
  .theme-light body {
    background: #fff; }
  .theme-dark body {
    background: #08090a; }

input,
button,
textarea,
a {
  border: none;
  box-sizing: border-box; }

a,
button {
  cursor: pointer; }

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased; }

a,
input,
button,
textarea {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  font-size: 1.8rem; }

.cool-heading {
  font-size: 3.5rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #009eed, #006ea5);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  color: transparent;
  letter-spacing: 0.5rem;
  transition: all 0.2s;
  margin-bottom: 3rem;
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
  @media (max-width: 56.25em) {
    .cool-heading {
      font-size: 3rem; } }
  @media (max-width: 43.75em) {
    .cool-heading {
      font-size: 2.5rem; } }
  @media (max-width: 31.25em) {
    .cool-heading {
      letter-spacing: 0.3rem; } }
  @media not all and (pointer: coarse) {
    .cool-heading:hover {
      transform: skewY(2deg) skewX(15deg) scale(1.1); }
      .theme-light .cool-heading:hover {
        text-shadow: 0.5rem 1rem 2rem rgba(20, 23, 22, 0.2); }
      .theme-dark .cool-heading:hover {
        text-shadow: 0.2rem 0.4rem 2rem rgba(56, 56, 56, 0.2); } }

/*
    OBS: It's usual to use '!important' in utility classes because they are supposed to override
    every other css applied to the given element
*/
.u-height-100 {
  height: 100% !important; }

.u-width-100 {
  width: 100% !important; }

.u-center-text {
  text-align: center !important; }

.u-margin-bottom-huge {
  margin-bottom: 12rem !important; }

.u-margin-bottom-big {
  margin-bottom: 8rem !important; }

.u-margin-bottom-medium {
  margin-bottom: 4rem !important; }

.u-margin-bottom-small {
  margin-bottom: 2rem !important; }

.u-margin-top-huge {
  margin-top: 12rem !important; }

.u-margin-top-big {
  margin-top: 8rem !important; }

.u-margin-top-medium {
  margin-top: 4rem !important; }

.u-margin-top-small {
  margin-top: 2rem !important; }

.u-margin-top-tiny {
  margin-top: 1rem !important; }

.u-margin-left-huge {
  margin-left: 12rem !important; }

.u-margin-left-big {
  margin-left: 8rem !important; }

.u-margin-left-medium {
  margin-left: 4rem !important; }

.u-margin-left-small {
  margin-left: 2rem !important; }

.u-margin-right-huge {
  margin-right: 12rem !important; }

.u-margin-right-big {
  margin-right: 8rem !important; }

.u-margin-right-medium {
  margin-right: 4rem !important; }

.u-margin-right-small {
  margin-right: 2rem !important; }

.u-padding-small {
  padding: 3rem !important; }

.u-flex {
  display: flex !important; }

.u-justify-content-center {
  justify-content: center !important; }

.u-justify-content-space-around {
  justify-content: space-around !important; }

.u-justify-content-space-between {
  justify-content: space-between !important; }

.u-justify-content-space-evenly {
  justify-content: space-evenly !important; }

.u-justify-content-flex-end {
  justify-content: flex-end !important; }

.u-justify-content-flex-start {
  justify-content: flex-start !important; }

.u-align-items-center {
  align-items: center !important; }

.u-align-items-flex-start {
  align-items: flex-start !important; }

.u-align-items-flex-end {
  align-items: flex-end !important; }

.u-align-self-stretch {
  align-self: stretch !important; }

.u-align-self-center {
  align-self: center !important; }

.u-flex-direction-column {
  flex-direction: column !important; }

.u-flex-direction-row {
  flex-direction: row !important; }

.u-disabled-btn {
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
  .u-disabled-btn, .u-disabled-btn:active {
    opacity: 0.5;
    pointer-events: none !important; }
  @media not all and (pointer: coarse) {
    .u-disabled-btn:hover {
      opacity: 0.5;
      pointer-events: none !important; } }

.u-discreet-disabled-btn {
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
  .u-discreet-disabled-btn, .u-discreet-disabled-btn:active {
    pointer-events: none !important; }
  @media not all and (pointer: coarse) {
    .u-discreet-disabled-btn:hover {
      pointer-events: none !important; } }

.u-margin-right-children-small > *:not(:last-child) {
  margin-right: 2rem !important; }

.u-overflow-hidden {
  overflow: hidden !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important; }

.u-no-transitions {
  transition: none !important; }

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  background-color: #fff; }
  .bg-video__content {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.bottom-message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem 6rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  z-index: 500;
  min-height: 8rem;
  align-items: center; }
  .theme-light .bottom-message {
    background-color: #d2dcdf; }
  .theme-dark .bottom-message {
    background-color: #141716; }
  .theme-light .bottom-message {
    color: #141716; }
  .theme-dark .bottom-message {
    color: #fff; }
  @media (max-width: 31.25em) {
    .bottom-message {
      padding: 2rem; } }
  .bottom-message-enter {
    opacity: 0;
    visibility: hidden; }
  .bottom-message-enter-active {
    opacity: 1;
    visibility: visible;
    transition: all 0.4s; }
  .bottom-message-exit {
    opacity: 1;
    visibility: visible; }
  .bottom-message-exit-active {
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s; }

.btn-flag {
  position: relative;
  display: inline-flex;
  height: 1.5rem;
  background: transparent;
  align-items: center; }
  .btn-flag > *:not(:last-child) {
    margin-right: 1rem; }
  .btn-flag__active {
    font-size: 1.3rem;
    display: flex;
    margin-left: 1rem; }
    .theme-light .btn-flag__active {
      color: #0092db; }
    .theme-dark .btn-flag__active {
      color: #0092db; }
  .btn-flag__flag {
    display: flex;
    height: 100%; }
  .btn-flag__text {
    font-size: 1rem;
    font-weight: 500; }
    .theme-light .btn-flag__text {
      color: #383838; }
    .theme-dark .btn-flag__text {
      color: #d0d0d0; }
    .btn-flag__text--thick {
      font-size: 1.6rem; }

.btn-color-circle-icon {
  display: inline-flex;
  height: 2.2rem;
  background: transparent;
  display: flex;
  align-items: center; }
  .btn-color-circle-icon > *:not(:last-child) {
    margin-right: 1rem; }
  .btn-color-circle-icon__icon {
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%; }
    .btn-color-circle-icon__icon--black {
      background-color: #08090a; }
    .btn-color-circle-icon__icon--white {
      background-color: #fff; }
  .btn-color-circle-icon--active .btn-color-circle-icon__icon {
    border: 1px solid #0092db; }
  .btn-color-circle-icon__text {
    font-size: 1rem;
    font-weight: 500; }
    .theme-light .btn-color-circle-icon__text {
      color: #383838; }
    .theme-dark .btn-color-circle-icon__text {
      color: #d0d0d0; }
    .btn-color-circle-icon__text--thick {
      font-size: 1.6rem; }

.btn-text {
  font-size: 1.6rem;
  background: transparent;
  font-weight: 500;
  text-decoration: none; }
  .theme-light .btn-text {
    color: #141716; }
  .theme-dark .btn-text {
    color: #fff; }

.btn-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 2rem;
  border-radius: 100rem;
  transition: all 0.1s;
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
  @media not all and (pointer: coarse) {
    .btn-primary:hover {
      transform: translateY(-2px);
      opacity: 0.9; } }
  @media not all and (pointer: coarse) {
    .btn-primary:active {
      transform: translateY(-1px); } }
  .btn-primary:not(:last-child) {
    margin-right: 1rem; }
  .btn-primary > *:not(:last-child) {
    margin-right: 1rem; }
  .btn-primary--icon {
    display: flex; }
    .btn-primary--icon svg {
      height: 2.2rem;
      fill: #fff; }
  .btn-primary--text {
    font-weight: 300;
    font-size: 1.5rem;
    color: #fff; }
  .btn-primary--thick {
    padding: 1rem 2rem; }
  .btn-primary--thick .btn-primary--text {
    font-size: 2rem;
    font-weight: 600; }
  .btn-primary--w100 {
    justify-content: center;
    width: 100%; }
  .btn-primary--thin {
    padding: 0.4rem 2rem; }
  .btn-primary--thin .btn-primary--text {
    font-size: 1.2rem; }
  .theme-light .btn-primary--color-primary {
    background-color: #0092db; }
  .theme-dark .btn-primary--color-primary {
    background-color: #0092db; }
  .theme-light .btn-primary--color-red {
    background-color: #d00000; }
  .theme-dark .btn-primary--color-red {
    background-color: #d00000; }
  .theme-light .btn-primary--color-green {
    background-color: #1db954; }
  .theme-dark .btn-primary--color-green {
    background-color: #1db954; }

.btn-icon {
  display: flex;
  align-self: center;
  background: transparent;
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
  .theme-light .btn-icon {
    color: #383838; }
  .theme-dark .btn-icon {
    color: #d0d0d0; }
  .btn-icon > *:not(:last-child) {
    margin-right: 1rem; }
  .theme-light .btn-icon--active.btn-icon--red .btn-icon--icon svg {
    fill: #d00000; }
  .theme-dark .btn-icon--active.btn-icon--red .btn-icon--icon svg {
    fill: #d00000; }
  .theme-light .btn-icon--active.btn-icon--red .btn-icon--number {
    color: #d00000; }
  .theme-dark .btn-icon--active.btn-icon--red .btn-icon--number {
    color: #d00000; }
  .btn-icon--icon {
    font-size: 2.1rem;
    display: flex; }
    .theme-light .btn-icon--icon svg {
      fill: #383838; }
    .theme-dark .btn-icon--icon svg {
      fill: #d0d0d0; }
  .btn-icon--number {
    font-size: 1.2rem; }
    .theme-light .btn-icon--number {
      color: #383838; }
    .theme-dark .btn-icon--number {
      color: #d0d0d0; }
  @media not all and (pointer: coarse) {
    .theme-light .btn-icon--orange:hover .btn-icon--icon svg {
      fill: #ff9f1c; }
    .theme-dark .btn-icon--orange:hover .btn-icon--icon svg {
      fill: #ff9f1c; }
    .theme-light .btn-icon--orange:hover .btn-icon--number {
      color: #ff9f1c; }
    .theme-dark .btn-icon--orange:hover .btn-icon--number {
      color: #ff9f1c; }
    .theme-light .btn-icon--primary:hover .btn-icon--icon svg {
      fill: #0092db; }
    .theme-dark .btn-icon--primary:hover .btn-icon--icon svg {
      fill: #0092db; }
    .theme-light .btn-icon--primary:hover .btn-icon--number {
      color: #0092db; }
    .theme-dark .btn-icon--primary:hover .btn-icon--number {
      color: #0092db; }
    .theme-light .btn-icon--red:hover .btn-icon--icon svg {
      fill: #d00000; }
    .theme-dark .btn-icon--red:hover .btn-icon--icon svg {
      fill: #d00000; }
    .theme-light .btn-icon--red:hover .btn-icon--number {
      color: #d00000; }
    .theme-dark .btn-icon--red:hover .btn-icon--number {
      color: #d00000; }
    .theme-light .btn-icon--green:hover .btn-icon--icon svg {
      fill: #1db954; }
    .theme-dark .btn-icon--green:hover .btn-icon--icon svg {
      fill: #1db954; }
    .theme-light .btn-icon--green:hover .btn-icon--number {
      color: #1db954; }
    .theme-dark .btn-icon--green:hover .btn-icon--number {
      color: #1db954; } }

.btn-bg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.1rem;
  width: 3.5rem;
  height: 3.5rem;
  color: #fff;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  border-radius: 100%;
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
  .btn-bg-icon--primary {
    background-color: #0092db; }
  @media not all and (pointer: coarse) {
    .btn-bg-icon:hover {
      -webkit-filter: brightness(90%);
              filter: brightness(90%); } }

.btn-icon-2 {
  display: flex;
  align-self: center;
  background: transparent;
  cursor: pointer;
  font-size: 2.1rem; }
  .theme-light .btn-icon-2--primary {
    color: #0092db; }
  .theme-dark .btn-icon-2--primary {
    color: #0092db; }
  .theme-light .btn-icon-2--primary svg {
    fill: #0092db; }
  .theme-dark .btn-icon-2--primary svg {
    fill: #0092db; }
  .theme-light .btn-icon-2--red {
    color: #d00000; }
  .theme-dark .btn-icon-2--red {
    color: #d00000; }
  .theme-light .btn-icon-2--red svg {
    fill: #d00000; }
  .theme-dark .btn-icon-2--red svg {
    fill: #d00000; }

.btn-icon-3 {
  display: flex;
  align-self: center;
  transition: color 0.3s;
  background: transparent;
  cursor: pointer;
  font-size: 2.1rem; }
  .theme-light .btn-icon-3 {
    color: #383838; }
  .theme-dark .btn-icon-3 {
    color: #d0d0d0; }
  .btn-icon-3--red {
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    @media not all and (pointer: coarse) {
      .theme-light .btn-icon-3--red:hover {
        color: #d00000; }
      .theme-dark .btn-icon-3--red:hover {
        color: #d00000; } }

.border-btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: color 0.25s;
  border: 0;
  font-size: 2rem;
  padding: 1rem; }
  .border-btn__icon {
    display: inline-flex; }
  .border-btn::before, .border-btn::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%; }
  .border-btn::before, .border-btn::after {
    border: 2px solid transparent;
    width: 0;
    height: 0; }
  .border-btn::before {
    top: 0;
    left: 0; }
  .border-btn::after {
    bottom: 0;
    right: 0; }
  .theme-light .border-btn {
    color: #141716; }
  .theme-dark .border-btn {
    color: #fff; }
  .theme-light .border-btn--active {
    color: #0092db; }
  .theme-dark .border-btn--active {
    color: #0092db; }
  .border-btn--active::before, .border-btn--active::after {
    width: 100%;
    height: 100%; }
  .border-btn--active::before {
    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s; }
    .theme-light .border-btn--active::before {
      border-top-color: #0092db; }
    .theme-dark .border-btn--active::before {
      border-top-color: #0092db; }
    .theme-light .border-btn--active::before {
      border-right-color: #0092db; }
    .theme-dark .border-btn--active::before {
      border-right-color: #0092db; }
  .border-btn--active::after {
    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s; }
    .theme-light .border-btn--active::after {
      border-bottom-color: #0092db; }
    .theme-dark .border-btn--active::after {
      border-bottom-color: #0092db; }
    .theme-light .border-btn--active::after {
      border-left-color: #0092db; }
    .theme-dark .border-btn--active::after {
      border-left-color: #0092db; }

.toggle-btn {
  border-radius: 100rem;
  position: relative;
  height: 4rem;
  width: 8rem;
  z-index: 1;
  display: flex; }
  .theme-light .toggle-btn {
    background-color: #d2dcdf; }
  .theme-dark .toggle-btn {
    background-color: #141716; }
  .toggle-btn__option {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem; }
    .theme-light .toggle-btn__option--green {
      color: #1db954; }
    .theme-dark .toggle-btn__option--green {
      color: #1db954; }
    .theme-light .toggle-btn__option--red {
      color: #d00000; }
    .theme-dark .toggle-btn__option--red {
      color: #d00000; }
  .toggle-btn__marker {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    border-radius: 100%; }
    .theme-light .toggle-btn__marker {
      background-color: #d0d0d0; }
    .theme-dark .toggle-btn__marker {
      background-color: #040b1d; }
    .toggle-btn__marker-enter {
      right: 0; }
    .toggle-btn__marker-enter-active {
      right: 50%;
      transition: all 0.3s; }
    .toggle-btn__marker-exit, .toggle-btn__marker-enter-done {
      right: 50%; }
    .toggle-btn__marker-exit-active {
      right: 0;
      transition: all 0.3s; }
    .toggle-btn__marker::after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: 100%;
      z-index: -1; }
      .theme-light .toggle-btn__marker::after {
        background-color: #0092db; }
      .theme-dark .toggle-btn__marker::after {
        background-color: #0092db; }

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  padding: 4rem 3rem;
  max-width: 60rem;
  width: 100%; }
  .form--no-padding {
    padding: 0; }
  .form > *:not(:last-child) {
    margin-bottom: 3rem; }
  .form__logo-box {
    align-self: center;
    height: 5rem; }
    .form__logo-box img {
      height: 100%; }
  .form__heading {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 120%; }
    .theme-light .form__heading {
      color: #141716; }
    .theme-dark .form__heading {
      color: #fff; }
  .form__description {
    font-size: 1.6rem;
    text-align: center;
    font-weight: 400;
    line-height: 140%; }
    .theme-light .form__description {
      color: #8c8c8c; }
    .theme-dark .form__description {
      color: #7a7a7a; }
  .form__input-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%; }
    .form__input-box > *:not(:last-child) {
      margin-bottom: 0.5rem; }
  .form__error {
    font-size: 1.4rem;
    font-weight: 600; }
    .theme-light .form__error {
      color: #d00000; }
    .theme-dark .form__error {
      color: #d00000; }
  .form__input {
    font-size: 1.6rem;
    position: relative;
    border-width: 1px;
    resize: none;
    border-style: solid;
    width: 100%;
    border-radius: 3px;
    padding: 1rem;
    background: transparent; }
    .theme-light .form__input {
      border-color: rgba(208, 208, 208, 0.7); }
    .theme-dark .form__input {
      border-color: rgba(255, 255, 255, 0.2); }
    .theme-light .form__input {
      color: #383838; }
    .theme-dark .form__input {
      color: #d0d0d0; }
    .form__input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .form__input:-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .form__input::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .form__input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
      .theme-light .form__input::-webkit-input-placeholder {
        color: #383838; }
      .theme-light .form__input:-ms-input-placeholder {
        color: #383838; }
      .theme-light .form__input::-ms-input-placeholder {
        color: #383838; }
      .theme-light .form__input::placeholder {
        color: #383838; }
      .theme-dark .form__input::-webkit-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .form__input:-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .form__input::-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .form__input::placeholder {
        color: #d0d0d0; }
    .form__input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */ }
      .theme-light .form__input:-ms-input-placeholder {
        color: #383838; }
      .theme-dark .form__input:-ms-input-placeholder {
        color: #d0d0d0; }
    .form__input::-ms-input-placeholder {
      /* Microsoft Edge */ }
      .theme-light .form__input::-ms-input-placeholder {
        color: #383838; }
      .theme-dark .form__input::-ms-input-placeholder {
        color: #d0d0d0; }
    .theme-light .form__input--error {
      border-color: #d00000; }
    .theme-dark .form__input--error {
      border-color: #d00000; }
  .form label {
    font-size: 1.4rem; }
    .theme-light .form label {
      color: #141716; }
    .theme-dark .form label {
      color: #fff; }
  .form__btn-row {
    display: flex; }
    .form__btn-row > *:not(:last-child) {
      margin-right: 2rem; }

.lazyImage-enter {
  opacity: 0; }

.lazyImage-enter-active {
  opacity: 1;
  transition: opacity 0.6s; }

.lazyImage-exit {
  opacity: 1; }

.lazyImage-exit-active {
  opacity: 0;
  transition: opacity 0.6s; }

.loading {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .theme-light .loading {
    background-color: #fff; }
  .theme-dark .loading {
    background-color: #08090a; }
  .loading-enter, .loading-appear {
    visibility: hidden;
    opacity: 0; }
  .loading-enter-active, .loading-appear-active {
    visibility: visible;
    opacity: 1;
    transition: all 0s 0.2s; }
  .loading-exit {
    visibility: visible;
    opacity: 1; }
  .loading-exit-active {
    visibility: hidden;
    opacity: 0;
    transition: all 0s; }

.loading-content {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 10;
  flex-direction: column; }
  .theme-light .loading-content {
    background-color: #fff; }
  .theme-dark .loading-content {
    background-color: #08090a; }
  .loading-content > *:not(:last-child) {
    margin-bottom: 2rem; }
  .loading-content__text {
    font-size: 2rem;
    font-weight: 400; }
    .theme-light .loading-content__text {
      color: #0092db; }
    .theme-dark .loading-content__text {
      color: #0092db; }
  .loading-content-enter, .loading-content-appear {
    opacity: 0;
    visibility: hidden; }
  .loading-content-enter-active, .loading-content-appear-active {
    opacity: 1;
    visibility: visible;
    transition: all 0s 0.2s; }
  .loading-content-exit {
    opacity: 1;
    visibility: visible; }
  .loading-content-exit-active {
    opacity: 0;
    visibility: hidden;
    transition: all 0s; }

.modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000; }
  .modal-enter {
    opacity: 0;
    visibility: hidden; }
  .modal-enter-active {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s; }
  .modal-exit {
    opacity: 1;
    visibility: visible; }
  .modal-exit-active {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s; }
  .modal__close-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .theme-light .modal__close-area {
      background-color: rgba(8, 9, 10, 0.8); }
    .theme-dark .modal__close-area {
      background-color: rgba(8, 9, 10, 0.9); }
  .modal__close-btn {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    color: #fff;
    font-size: 2rem; }
    .theme-light .modal__close-btn {
      background-color: #d00000; }
    .theme-dark .modal__close-btn {
      background-color: #d00000; }
  .modal__box {
    position: absolute;
    top: 50%;
    overflow: hidden;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90vw;
    max-height: 90vh;
    border-radius: 1rem;
    transform-origin: top left; }
    .theme-light .modal__box {
      background-color: #fff; }
    .theme-dark .modal__box {
      background-color: #141716; }
    .modal__box-enter, .modal__box-appear {
      transform: scale(0.5) translate(-50%, -50%); }
    .modal__box-enter-active, .modal__box-appear-active {
      transform: scale(1) translate(-50%, -50%);
      transition: all 0.3s 0.2s; }
    .modal__box-exit {
      transform: scale(1) translate(-50%, -50%); }
    .modal__box-exit-active {
      transform: scale(0.5) translate(-50%, -50%);
      transition: all 0.3s 0.2s; }
  .modal__content {
    max-width: inherit;
    max-height: inherit;
    overflow-y: auto;
    overflow-x: hidden; }
    .modal__content--small {
      width: 40rem;
      height: 40rem; }
    .modal__content--medium {
      width: 50rem;
      height: 50rem; }
    .modal__content--big {
      width: 75rem;
      height: 65rem; }
    .modal__content--large {
      width: 95rem;
      height: 80rem; }
    @media (max-width: 56.25em) {
      .modal__content--small, .modal__content--medium, .modal__content--big, .modal__content--large {
        width: 80vw;
        height: 80vh; } }
    @media (max-width: 43.75em) {
      .modal__content--small, .modal__content--medium, .modal__content--big, .modal__content--large {
        width: 100vw;
        height: 100vh; } }
    .modal__content--fit-content-small {
      width: 50rem; }
    .modal__content--fit-content-medium {
      width: 60rem; }
    .modal__content--small-box {
      width: 40rem;
      height: 40rem; }

.no-content {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 4rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem; }
  .no-content__icon {
    font-size: 5.5rem;
    display: flex;
    color: #fff; }
    .theme-light .no-content__icon--red {
      color: #d00000; }
    .theme-dark .no-content__icon--red {
      color: #d00000; }
    .theme-light .no-content__icon--primary {
      color: #0092db; }
    .theme-dark .no-content__icon--primary {
      color: #0092db; }
  .no-content__text {
    font-size: 2.8rem;
    text-align: center;
    color: #fff;
    font-weight: 600; }
    .theme-light .no-content__text--red {
      color: #d00000; }
    .theme-dark .no-content__text--red {
      color: #d00000; }
    .theme-light .no-content__text--primary {
      color: #0092db; }
    .theme-dark .no-content__text--primary {
      color: #0092db; }

.popup-message {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem; }
  .popup-message > *:not(:last-child) {
    margin-bottom: 3rem; }
  .popup-message__icon {
    font-size: 8rem;
    display: inline-flex; }
    .theme-light .popup-message__icon--sucess {
      color: #1db954; }
    .theme-dark .popup-message__icon--sucess {
      color: #1db954; }
    .theme-light .popup-message__icon--error {
      color: #d00000; }
    .theme-dark .popup-message__icon--error {
      color: #d00000; }
  .popup-message__text {
    font-size: 2rem;
    font-weight: 500;
    text-align: center; }
    .theme-light .popup-message__text--sucess {
      color: #1db954; }
    .theme-dark .popup-message__text--sucess {
      color: #1db954; }
    .theme-light .popup-message__text--error {
      color: #d00000; }
    .theme-dark .popup-message__text--error {
      color: #d00000; }

.post-box {
  display: flex;
  flex-direction: column;
  max-width: 70rem;
  width: 100%;
  justify-self: center; }
  .post-box--thats-it {
    border-radius: 3px;
    display: flex;
    padding: 4rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem; }
  .post-box__icon {
    font-size: 5.5rem;
    display: flex;
    color: #fff; }
    .theme-light .post-box__icon--red {
      color: #d00000; }
    .theme-dark .post-box__icon--red {
      color: #d00000; }
    .theme-light .post-box__icon--primary {
      color: #0092db; }
    .theme-dark .post-box__icon--primary {
      color: #0092db; }
  .post-box__text {
    font-size: 2.8rem;
    text-align: center;
    color: #fff;
    font-weight: 600; }
    .theme-light .post-box__text--red {
      color: #d00000; }
    .theme-dark .post-box__text--red {
      color: #d00000; }
    .theme-light .post-box__text--primary {
      color: #0092db; }
    .theme-dark .post-box__text--primary {
      color: #0092db; }

.post-header {
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  display: grid;
  width: 100%;
  grid-template-rows: [row-header-start] minmax(6rem, -webkit-min-content) [row-header-end row-cover-start] -webkit-min-content [row-cover-end row-footer-start] minmax(6rem, -webkit-min-content) [row-footer-end row-grid-end];
  grid-template-rows: [row-header-start] minmax(6rem, min-content) [row-header-end row-cover-start] min-content [row-cover-end row-footer-start] minmax(6rem, min-content) [row-footer-end row-grid-end];
  grid-template-columns: [padding-left-1] 2rem [padding-left-2] 2rem [col-content-start] 1fr [col-content-end] 2rem [padding-right-2] 2rem [padding-right-1]; }
  .theme-light .post-header {
    border-color: #d2dcdf; }
  .theme-dark .post-header {
    border-color: #141716; }
  @media (max-width: 31.25em) {
    .post-header {
      border: none; } }
  .post-header__header {
    grid-row: row-header-start / row-header-end;
    grid-column: padding-left-2 / padding-right-2;
    padding: 0.5rem 0;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: [col-picture-start] 3.5rem [col-picture-end col-login-start] 1fr [col-login-end col-date-start] -webkit-min-content [col-date-end col-grid-end];
    grid-template-columns: [col-picture-start] 3.5rem [col-picture-end col-login-start] 1fr [col-login-end col-date-start] min-content [col-date-end col-grid-end];
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    align-items: center; }
  .post-header__profile-picture {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    margin-right: 1rem;
    overflow: hidden; }
    .post-header__profile-picture img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .post-header__login {
    font-weight: 600;
    font-size: 1.6rem;
    position: relative;
    overflow: hidden;
    height: 100%; }
    .theme-light .post-header__login {
      color: #141716; }
    .theme-dark .post-header__login {
      color: #fff; }
    .post-header__login--text {
      position: absolute;
      white-space: nowrap;
      width: 100%;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
  .post-header__publish-time {
    font-size: 1.6rem; }
    .theme-light .post-header__publish-time {
      color: #383838; }
    .theme-dark .post-header__publish-time {
      color: #d0d0d0; }
  .post-header__cover {
    position: relative;
    grid-row: row-cover-start / row-cover-end;
    grid-column: padding-left-1 / padding-right-1;
    height: 100%;
    overflow: hidden; }
    .post-header__cover--placeholder {
      position: relative;
      width: 100%;
      padding-top: calc((9 / 16) * 100%); }
      .post-header__cover--placeholder::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        border: 4px solid;
        border-color: transparent #0092db;
        -webkit-animation: loadingBall 1.5s linear infinite;
                animation: loadingBall 1.5s linear infinite;
        -webkit-animation-fill-mode: backwards;
                animation-fill-mode: backwards; }
        @media (max-width: 43.75em) {
          .post-header__cover--placeholder::after {
            width: 4rem;
            height: 4rem; } }
    .post-header__cover img {
      width: 100%;
      height: 100%;
      max-height: 50rem;
      object-fit: contain; }
  .post-header__btn-box {
    padding: 2rem 0;
    grid-row: row-footer-start / row-footer-end;
    grid-column: col-content-start / col-content-end;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
    justify-items: center;
    align-items: center;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem; }
  @media (max-width: 43.75em) {
    .post-header__btn-number {
      display: none; } }

.post-content {
  overflow: hidden;
  position: relative;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  padding: 1.5rem 1rem;
  display: grid;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr;
  grid-row-gap: 3rem;
  row-gap: 3rem; }
  .theme-light .post-content {
    color: #141716; }
  .theme-dark .post-content {
    color: #fff; }
  @media (max-width: 31.25em) {
    .post-content {
      padding: 1.5rem 2rem; } }
  .post-content__icons-box {
    display: grid;
    justify-content: end;
    grid-template-columns: repeat(auto-fit, minmax(2rem, -webkit-min-content));
    grid-template-columns: repeat(auto-fit, minmax(2rem, min-content));
    grid-gap: 4rem;
    gap: 4rem; }
    @media (max-width: 31.25em) {
      .post-content__icons-box {
        justify-content: center;
        grid-gap: 3rem;
        gap: 3rem; } }
  .post-content__icon {
    font-size: 2rem;
    display: flex; }
    .theme-light .post-content__icon {
      color: #0092db; }
    .theme-dark .post-content__icon {
      color: #0092db; }
  .post-content__categories-box {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem; }
  .post-content__comments-box {
    display: grid;
    grid-template-rows: repeat(3, -webkit-min-content);
    grid-template-rows: repeat(3, min-content);
    grid-column: 1fr;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem; }
  .post-content__comments-header {
    grid-row: 1 / 2;
    font-size: 2rem;
    font-weight: 300; }
    .theme-light .post-content__comments-header {
      color: #383838; }
    .theme-dark .post-content__comments-header {
      color: #d0d0d0; }
  .post-content__comments-input {
    font-size: 1.8rem;
    grid-row: 2 / 3;
    border-radius: 100rem;
    width: 100%;
    height: 3.7rem;
    display: flex;
    padding: 0 1rem;
    align-items: center; }
    .theme-light .post-content__comments-input {
      color: #141716; }
    .theme-dark .post-content__comments-input {
      color: #fff; }
    .post-content__comments-input--loading {
      opacity: 0.6; }
    .theme-light .post-content__comments-input {
      background-color: rgba(0, 146, 219, 0.65); }
    .theme-dark .post-content__comments-input {
      background-color: rgba(0, 146, 219, 0.45); }
    .post-content__comments-input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .post-content__comments-input:-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .post-content__comments-input::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .post-content__comments-input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
      .theme-light .post-content__comments-input::-webkit-input-placeholder {
        color: #383838; }
      .theme-light .post-content__comments-input:-ms-input-placeholder {
        color: #383838; }
      .theme-light .post-content__comments-input::-ms-input-placeholder {
        color: #383838; }
      .theme-light .post-content__comments-input::placeholder {
        color: #383838; }
      .theme-dark .post-content__comments-input::-webkit-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .post-content__comments-input:-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .post-content__comments-input::-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .post-content__comments-input::placeholder {
        color: #d0d0d0; }
    .post-content__comments-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */ }
      .theme-light .post-content__comments-input:-ms-input-placeholder {
        color: #383838; }
      .theme-dark .post-content__comments-input:-ms-input-placeholder {
        color: #d0d0d0; }
    .post-content__comments-input::-ms-input-placeholder {
      /* Microsoft Edge */ }
      .theme-light .post-content__comments-input::-ms-input-placeholder {
        color: #383838; }
      .theme-dark .post-content__comments-input::-ms-input-placeholder {
        color: #d0d0d0; }
  .post-content__comments-input-box {
    position: relative; }
  .post-content__category {
    display: flex;
    cursor: pointer;
    border: 1px solid #0092db;
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 100rem;
    padding: 0 0.5rem; }
    .theme-light .post-content__category {
      color: #383838; }
    .theme-dark .post-content__category {
      color: #d0d0d0; }
  .post-content__comments {
    display: flex;
    flex-direction: column; }
    .post-content__comments > *:not(:last-child) {
      margin-bottom: 1rem; }
  .post-content__fetching-comments {
    display: flex;
    width: 100%;
    justify-content: center; }
  .post-content__has-more-comments {
    align-self: center;
    display: flex;
    font-size: 2.2rem;
    background: transparent;
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    .theme-light .post-content__has-more-comments {
      color: #141716; }
    .theme-dark .post-content__has-more-comments {
      color: #fff; }
    @media not all and (pointer: coarse) {
      .theme-light .post-content__has-more-comments:hover {
        color: #0092db; }
      .theme-dark .post-content__has-more-comments:hover {
        color: #0092db; } }
  .post-content__has-more-replies {
    position: relative;
    align-self: start;
    display: flex;
    font-size: 1.6rem;
    margin-left: 4rem;
    background: transparent; }
    .theme-light .post-content__has-more-replies {
      color: #0092db; }
    .theme-dark .post-content__has-more-replies {
      color: #0092db; }
    .post-content__has-more-replies::before {
      content: "";
      height: 2px;
      width: 1.5rem;
      position: absolute;
      top: 50%;
      left: -2.75rem;
      transform: translateY(-50%); }
      .theme-light .post-content__has-more-replies::before {
        background-color: #0092db; }
      .theme-dark .post-content__has-more-replies::before {
        background-color: #0092db; }
  .post-content__comments-loading {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%); }
  .post-content--closed {
    display: none; }
  .post-content--opened {
    display: grid; }

.post-comment {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, -webkit-min-content);
  grid-template-rows: repeat(3, min-content);
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem; }
  .post-comment--reply {
    padding-left: 4rem; }
  .post-comment__input-box {
    position: relative; }
  .post-comment__header {
    position: relative;
    grid-row: 1 / 2;
    display: flex;
    align-items: center; }
    .post-comment__header > *:not(:last-child) {
      margin-right: 2rem; }
    .post-comment__header--primary-icon {
      font-size: 1.5rem;
      display: flex; }
      .theme-light .post-comment__header--primary-icon {
        color: #0092db; }
      .theme-dark .post-comment__header--primary-icon {
        color: #0092db; }
    .post-comment__header--green-icon {
      font-size: 1.5rem;
      display: flex; }
      .theme-light .post-comment__header--green-icon {
        color: #1db954; }
      .theme-dark .post-comment__header--green-icon {
        color: #1db954; }
    .post-comment__header--primary {
      font-weight: 500;
      font-size: 1.6rem; }
      .theme-light .post-comment__header--primary {
        color: #141716; }
      .theme-dark .post-comment__header--primary {
        color: #fff; }
    .post-comment__header--secondary {
      font-size: 1.6rem;
      font-weight: 300; }
      .theme-light .post-comment__header--secondary {
        color: #383838; }
      .theme-dark .post-comment__header--secondary {
        color: #d0d0d0; }
  .post-comment--reply .post-comment__header::before {
    content: "";
    height: 2px;
    width: 1.5rem;
    position: absolute;
    top: 50%;
    left: -2.75rem;
    transform: translateY(-50%); }
    .theme-light .post-comment--reply .post-comment__header::before {
      background-color: #0092db; }
    .theme-dark .post-comment--reply .post-comment__header::before {
      background-color: #0092db; }
  .post-comment__text {
    grid-row: 2 / 3;
    font-size: 1.4rem; }
    .theme-light .post-comment__text {
      color: #141716; }
    .theme-dark .post-comment__text {
      color: #fff; }
  .post-comment__reply-input {
    grid-row: 3 / 4;
    border-radius: 100rem;
    width: 100%;
    height: 3.7rem;
    font-size: 1.8rem;
    display: flex;
    padding: 0 1rem;
    align-items: center; }
    .theme-light .post-comment__reply-input {
      color: #141716; }
    .theme-dark .post-comment__reply-input {
      color: #fff; }
    .post-comment__reply-input--loading {
      opacity: 0.6; }
    .theme-light .post-comment__reply-input {
      background-color: rgba(0, 146, 219, 0.65); }
    .theme-dark .post-comment__reply-input {
      background-color: rgba(0, 146, 219, 0.45); }
    .post-comment__reply-input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .post-comment__reply-input:-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .post-comment__reply-input::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .post-comment__reply-input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
      .theme-light .post-comment__reply-input::-webkit-input-placeholder {
        color: #383838; }
      .theme-light .post-comment__reply-input:-ms-input-placeholder {
        color: #383838; }
      .theme-light .post-comment__reply-input::-ms-input-placeholder {
        color: #383838; }
      .theme-light .post-comment__reply-input::placeholder {
        color: #383838; }
      .theme-dark .post-comment__reply-input::-webkit-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .post-comment__reply-input:-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .post-comment__reply-input::-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .post-comment__reply-input::placeholder {
        color: #d0d0d0; }
    .post-comment__reply-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */ }
      .theme-light .post-comment__reply-input:-ms-input-placeholder {
        color: #383838; }
      .theme-dark .post-comment__reply-input:-ms-input-placeholder {
        color: #d0d0d0; }
    .post-comment__reply-input::-ms-input-placeholder {
      /* Microsoft Edge */ }
      .theme-light .post-comment__reply-input::-ms-input-placeholder {
        color: #383838; }
      .theme-dark .post-comment__reply-input::-ms-input-placeholder {
        color: #d0d0d0; }
    .post-comment__reply-input-enter {
      height: 0;
      visibility: hidden;
      opacity: 0; }
    .post-comment__reply-input-enter-active {
      height: 3.7rem;
      visibility: visible;
      opacity: 1;
      transition: height 0.3s, opacity 0.3s 0.3s, visibility 0.6s; }
    .post-comment__reply-input-exit {
      height: 3.7rem;
      visibility: visible;
      opacity: 1; }
    .post-comment__reply-input-exit-active {
      height: 0;
      visibility: hidden;
      opacity: 0;
      transition: height 0.3s 0.3s, opacity 0.3s, visibility 0.6s; }
  .post-comment__reply-btn {
    font-size: 1.2rem;
    font-weight: 500;
    background: transparent; }
    .theme-light .post-comment__reply-btn {
      color: #383838; }
    .theme-dark .post-comment__reply-btn {
      color: #d0d0d0; }

.post-comment-modal {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 2fr; }
  .theme-light .post-comment-modal {
    background-color: #d2dcdf; }
  .theme-dark .post-comment-modal {
    background-color: #141716; }
  @media (max-width: 43.75em) {
    .post-comment-modal {
      grid-template-columns: 1fr; } }
  .post-comment-modal__img {
    grid-column: 1 / 2;
    position: relative; }
    .theme-light .post-comment-modal__img {
      background-color: #0092db; }
    .theme-dark .post-comment-modal__img {
      background-color: #0092db; }
    @media (max-width: 43.75em) {
      .post-comment-modal__img {
        display: none; } }
    .post-comment-modal__img img {
      position: absolute;
      height: 90%;
      width: 100%;
      object-fit: contain;
      object-position: bottom;
      bottom: 0;
      right: 0; }
  .post-comment-modal__checkbox-group {
    display: grid;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
    grid-auto-flow: column;
    align-items: center;
    padding: 0 1rem; }
  .post-comment-modal__checkbox-input {
    display: none !important; }
  .post-comment-modal__checkbox-btn {
    height: 3rem;
    width: 3rem;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    font-size: 1.5rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center; }
    .theme-light .post-comment-modal__checkbox-btn {
      border-color: #383838; }
    .theme-dark .post-comment-modal__checkbox-btn {
      border-color: #d0d0d0; }
    .theme-light .post-comment-modal__checkbox-btn--checked {
      background-color: #0092db; }
    .theme-dark .post-comment-modal__checkbox-btn--checked {
      background-color: #0092db; }
  .post-comment-modal--error {
    font-size: 1.4rem;
    font-weight: 600; }
    .theme-light .post-comment-modal--error {
      color: #d00000; }
    .theme-dark .post-comment-modal--error {
      color: #d00000; }
  .post-comment-modal__checkbox-text {
    font-size: 1.3rem; }
    .theme-light .post-comment-modal__checkbox-text {
      color: #383838; }
    .theme-dark .post-comment-modal__checkbox-text {
      color: #d0d0d0; }
    @media (max-width: 43.75em) {
      .post-comment-modal__checkbox-text {
        font-size: 1.4rem; } }
  .post-comment-modal__content {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem; }
    @media (max-width: 43.75em) {
      .post-comment-modal__content {
        grid-column: auto; } }
  .post-comment-modal__heading {
    font-size: 2.6rem;
    font-weight: 600; }
    .theme-light .post-comment-modal__heading {
      color: #141716; }
    .theme-dark .post-comment-modal__heading {
      color: #fff; }
  .post-comment-modal__submit-btn {
    display: flex;
    justify-content: flex-end; }
  .post-comment-modal__input-box {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
  .post-comment-modal__input {
    border-radius: 100rem;
    width: 100%;
    display: flex;
    padding: 0.5rem 1rem;
    font-size: 1.6rem;
    align-items: center; }
    .theme-light .post-comment-modal__input {
      color: #141716; }
    .theme-dark .post-comment-modal__input {
      color: #fff; }
    .post-comment-modal__input--error {
      border-width: 1px;
      border-style: solid; }
      .theme-light .post-comment-modal__input--error {
        border-color: #d00000; }
      .theme-dark .post-comment-modal__input--error {
        border-color: #d00000; }
    .theme-light .post-comment-modal__input {
      background-color: #fff; }
    .theme-dark .post-comment-modal__input {
      background-color: #08090a; }
    .post-comment-modal__input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .post-comment-modal__input:-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .post-comment-modal__input::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .post-comment-modal__input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
      .theme-light .post-comment-modal__input::-webkit-input-placeholder {
        color: #383838; }
      .theme-light .post-comment-modal__input:-ms-input-placeholder {
        color: #383838; }
      .theme-light .post-comment-modal__input::-ms-input-placeholder {
        color: #383838; }
      .theme-light .post-comment-modal__input::placeholder {
        color: #383838; }
      .theme-dark .post-comment-modal__input::-webkit-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .post-comment-modal__input:-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .post-comment-modal__input::-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .post-comment-modal__input::placeholder {
        color: #d0d0d0; }
    .post-comment-modal__input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */ }
      .theme-light .post-comment-modal__input:-ms-input-placeholder {
        color: #383838; }
      .theme-dark .post-comment-modal__input:-ms-input-placeholder {
        color: #d0d0d0; }
    .post-comment-modal__input::-ms-input-placeholder {
      /* Microsoft Edge */ }
      .theme-light .post-comment-modal__input::-ms-input-placeholder {
        color: #383838; }
      .theme-dark .post-comment-modal__input::-ms-input-placeholder {
        color: #d0d0d0; }

.profile-card {
  font-family: "Poppins", sans-serif;
  position: relative;
  display: grid;
  width: 100%;
  max-width: 70rem;
  height: 45rem;
  grid-template-rows: 1fr;
  perspective: 100rem;
  grid-template-columns: [column-picture-start] 50% [column-picture-end column-info-start] 50% [column-info-end]; }
  @media (max-width: 56.25em) {
    .profile-card {
      max-width: 35rem;
      grid-template-columns: [grid-start] 1fr [grid-end]; } }
  .profile-card__picture-box {
    grid-row: 1 / 2;
    grid-column: column-picture-start / column-picture-end;
    position: relative;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .theme-light .profile-card__picture-box {
      box-shadow: 0 1rem 2rem rgba(8, 9, 10, 0.25); }
    .theme-dark .profile-card__picture-box {
      box-shadow: 0 1rem 2rem rgba(4, 11, 29, 0.35); }
    @media (max-width: 56.25em) {
      .profile-card__picture-box {
        grid-column: grid-start / grid-end; }
        .profile-card__picture-box-enter {
          transform: rotateY(0); }
        .profile-card__picture-box-enter-active {
          transform: rotateY(-180deg);
          transition: all 0.6s; }
        .profile-card__picture-box-enter-done, .profile-card__picture-box-exit {
          transform: rotateY(-180deg); }
        .profile-card__picture-box-exit-active {
          transform: rotateY(0);
          transition: all 0.6s; } }
  .profile-card__picture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden; }
    .profile-card__picture img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .profile-card__picture-label {
    position: absolute;
    top: 2rem;
    text-transform: uppercase;
    right: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    color: #fff;
    background-color: #0092db;
    font-size: 1.2rem;
    font-weight: 700;
    box-shadow: 0 1.5rem 3rem rgba(8, 9, 10, 0.2); }
  .profile-card__picture-identifier {
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: #0092db;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
  .profile-card__picture-icon {
    position: absolute;
    display: flex;
    background: transparent;
    bottom: 2rem;
    left: 2rem;
    font-size: 3rem;
    color: #fff; }
  .profile-card__picture-footer {
    max-width: 50%;
    line-height: 100%;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    color: #fff;
    font-size: 2rem;
    font-weight: 600; }
  .profile-card__info-boxes-container {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    grid-row: 1 / 2;
    grid-column: column-info-start / column-info-end; }
    .theme-light .profile-card__info-boxes-container {
      background-color: #d2dcdf; }
    .theme-dark .profile-card__info-boxes-container {
      background-color: #141716; }
    .theme-light .profile-card__info-boxes-container {
      box-shadow: 0 1rem 2rem rgba(8, 9, 10, 0.25); }
    .theme-dark .profile-card__info-boxes-container {
      box-shadow: 0 1rem 2rem rgba(4, 11, 29, 0.35); }
    @media (max-width: 56.25em) {
      .profile-card__info-boxes-container {
        grid-column: grid-start / grid-end;
        transform: rotateY(180deg);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden; }
        .profile-card__info-boxes-container-enter {
          transform: rotateY(180deg); }
        .profile-card__info-boxes-container-enter-active {
          transform: rotateY(0);
          transition: all 0.6s; }
        .profile-card__info-boxes-container-enter-done, .profile-card__info-boxes-container-exit {
          transform: rotateY(0); }
        .profile-card__info-boxes-container-exit-active {
          transform: rotateY(180deg);
          transition: all 0.6s; } }
  .profile-card__info-content {
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 4rem; }
    .profile-card__info-content--center {
      align-items: center; }
    .profile-card__info-content > *:not(:last-child) {
      margin-bottom: 3rem; }
  .profile-card__info-box {
    top: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .theme-light .profile-card__info-box {
      background-color: #d2dcdf; }
    .theme-dark .profile-card__info-box {
      background-color: #141716; }
    .profile-card__info-box--1 {
      transform: translateY(0); }
      .profile-card__info-box--1-enter {
        transform: translateY(0); }
      .profile-card__info-box--1-enter-active {
        transform: translateY(-100%);
        transition: all 0.6s; }
      .profile-card__info-box--1-enter-done, .profile-card__info-box--1-exit {
        transform: translateY(-100%); }
      .profile-card__info-box--1-exit-active {
        transform: translateY(0);
        transition: all 0.6s; }
    .profile-card__info-box--2 {
      transform: translateY(100%); }
      .profile-card__info-box--2-enter {
        transform: translateY(100%); }
      .profile-card__info-box--2-enter-active {
        transform: translateY(0);
        transition: all 0.6s; }
      .profile-card__info-box--2-enter-done, .profile-card__info-box--2-exit {
        transform: translateY(0); }
      .profile-card__info-box--2-exit-active {
        transform: translateY(100%);
        transition: all 0.6s; }
  .profile-card__info-group {
    display: flex;
    width: 100%; }
    .profile-card__info-group--column {
      flex-direction: column; }
      .profile-card__info-group--column > *:not(:last-child) {
        margin-bottom: 3rem; }
  .profile-card__info-label {
    font-size: 2.3rem;
    font-weight: 300;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    line-height: 1rem; }
    .theme-light .profile-card__info-label {
      color: #0092db; }
    .theme-dark .profile-card__info-label {
      color: #0092db; }
  .profile-card__info-text {
    font-size: 1.4rem;
    font-weight: 400;
    width: 100%;
    background: transparent;
    resize: none;
    word-wrap: break-word;
    word-break: break-word;
    display: inline; }
    .theme-light .profile-card__info-text {
      color: #383838; }
    .theme-dark .profile-card__info-text {
      color: #d0d0d0; }
  .profile-card__info-warning {
    font-size: 1.4rem;
    width: 100%;
    font-weight: 500;
    display: flex;
    justify-content: center;
    border-radius: 3px;
    padding: 0.5rem 1rem; }
    .profile-card__info-warning--red {
      border: 2px solid #d00000;
      color: #d00000; }
    .profile-card__info-warning--green {
      border: 2px solid #1db954;
      color: #1db954; }
  .profile-card__info-icon {
    margin-right: 1rem;
    display: flex;
    align-items: center; }
    .theme-light .profile-card__info-icon {
      color: #0092db; }
    .theme-dark .profile-card__info-icon {
      color: #0092db; }
    .profile-card__info-icon svg {
      height: 1.5rem; }
  .profile-card__rotate-btn {
    position: absolute;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    align-items: center;
    justify-content: center;
    color: #fff;
    bottom: 0;
    cursor: pointer;
    z-index: 10;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    left: 50%;
    transform: translate(-50%, 50%);
    display: none;
    font-size: 2.5rem; }
    .theme-light .profile-card__rotate-btn {
      box-shadow: 0 1rem 2rem rgba(8, 9, 10, 0.25); }
    .theme-dark .profile-card__rotate-btn {
      box-shadow: 0 1rem 2rem rgba(4, 11, 29, 0.35); }
    .theme-light .profile-card__rotate-btn {
      background-color: #0092db; }
    .theme-dark .profile-card__rotate-btn {
      background-color: #0092db; }
    @media (max-width: 56.25em) {
      .profile-card__rotate-btn {
        display: flex; } }
    .profile-card__rotate-btn-enter {
      transform: translate(-50%, 50%); }
    .profile-card__rotate-btn-enter-active {
      transform: translate(-50%, 50%) rotateY(-180deg);
      transition: all 0.6s; }
    .profile-card__rotate-btn-enter-done, .profile-card__rotate-btn-exit {
      transform: translate(-50%, 50%) rotateY(-180deg); }
    .profile-card__rotate-btn-exit-active {
      transform: translate(-50%, 50%);
      transition: all 0.6s; }
  .profile-card__back-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 3rem;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    display: none;
    z-index: 10; }
    .theme-light .profile-card__back-btn {
      background-color: #d00000; }
    .theme-dark .profile-card__back-btn {
      background-color: #d00000; }
    @media (max-width: 56.25em) {
      .profile-card__back-btn {
        display: flex; } }
  .profile-card__info-box-btn {
    position: absolute;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    bottom: 0;
    left: 77.5%;
    transform: translate(-50%, 50%);
    cursor: pointer;
    z-index: 10; }
    .theme-light .profile-card__info-box-btn {
      box-shadow: 0 1rem 2rem rgba(8, 9, 10, 0.25); }
    .theme-dark .profile-card__info-box-btn {
      box-shadow: 0 1rem 2rem rgba(4, 11, 29, 0.35); }
    .theme-light .profile-card__info-box-btn {
      background-color: #0092db; }
    .theme-dark .profile-card__info-box-btn {
      background-color: #0092db; }
    @media (max-width: 56.25em) {
      .profile-card__info-box-btn {
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        left: 50%;
        transform: translate(-50%, 50%) rotateY(180deg); } }
    .profile-card__info-box-btn::before, .profile-card__info-box-btn::after {
      position: absolute;
      content: "";
      height: 3px;
      width: 1.5rem;
      background-color: #fff;
      border-radius: 100rem;
      top: 50%;
      left: 50%; }
    .profile-card__info-box-btn::before {
      transform: translate(-50%, -50%); }
    .profile-card__info-box-btn::after {
      transform: translate(-50%, -50%) rotateZ(90deg); }
    .profile-card__info-box-btn-enter::after {
      transform: translate(-50%, -50%) rotateZ(90deg); }
    .profile-card__info-box-btn-enter-active::after {
      transform: translate(-50%, -50%);
      transition: all 0.3s; }
    .profile-card__info-box-btn-enter-done::after, .profile-card__info-box-btn-exit::after {
      transform: translate(-50%, -50%); }
    .profile-card__info-box-btn-exit-active::after {
      transform: translate(-50%, -50%) rotateZ(90deg);
      transition: all 0.3s; }
    .profile-card__info-box-btn--rotate-enter {
      transform: translate(-50%, 50%) rotateY(180deg); }
    .profile-card__info-box-btn--rotate-enter-active {
      transform: translate(-50%, 50%);
      transition: all 0.6s; }
    .profile-card__info-box-btn--rotate-enter-done, .profile-card__info-box-btn--rotate-exit {
      transform: translate(-50%, 50%); }
    .profile-card__info-box-btn--rotate-exit-active {
      transform: translate(-50%, 50%) rotateY(180deg);
      transition: all 0.6s; }
  .profile-card__option {
    padding: 2rem 4rem;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 400;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    .theme-light .profile-card__option {
      color: #383838; }
    .theme-dark .profile-card__option {
      color: #d0d0d0; }
    @media not all and (pointer: coarse) {
      .theme-light .profile-card__option:hover {
        color: #0092db; }
      .theme-dark .profile-card__option:hover {
        color: #0092db; } }
    .profile-card__option > *:not(:last-child) {
      margin-right: 2rem; }
    .profile-card__option:not(:last-child) {
      border-bottom-style: solid;
      border-bottom-width: 1px; }
      .theme-light .profile-card__option:not(:last-child) {
        border-bottom-color: #7a7a7a; }
      .theme-dark .profile-card__option:not(:last-child) {
        border-bottom-color: #d0d0d0; }

.team-card {
  display: grid;
  position: relative;
  grid-template-rows: [row-picture-start] -webkit-min-content [row-picture-end row-caption-start] -webkit-min-content [row-caption-end];
  grid-template-rows: [row-picture-start] min-content [row-picture-end row-caption-start] min-content [row-caption-end];
  grid-template-columns: minmax(-webkit-min-content, 35rem);
  grid-template-columns: minmax(min-content, 35rem);
  grid-row-gap: 1rem;
  row-gap: 1rem;
  perspective: 100rem;
  cursor: pointer;
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
  @media not all and (pointer: coarse) {
    .team-card:hover .team-card__picture {
      transform: rotateY(-180deg); }
    .team-card:hover .team-card__text {
      transform: rotateY(0); } }
  .team-card__picture {
    position: relative;
    grid-row: row-picture-start / row-picture-end;
    width: 100%;
    height: 48rem;
    border-radius: 1rem;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: transform 0.4s ease-out; }
    .theme-light .team-card__picture {
      box-shadow: 0 1rem 2rem rgba(8, 9, 10, 0.25); }
    .theme-dark .team-card__picture {
      box-shadow: 0 1rem 2rem rgba(4, 11, 29, 0.35); }
    .team-card__picture-enter {
      transform: rotateY(0); }
    .team-card__picture-enter-active {
      transform: rotateY(-180deg); }
    .team-card__picture-exit, .team-card__picture-enter-done {
      transform: rotateY(-180deg); }
    .team-card__picture-exit-active {
      transform: rotateY(0); }
    .team-card__picture img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .team-card__caption {
    grid-row: row-caption-start / row-caption-end;
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem; }
    .team-card__caption--icon {
      color: #0092db;
      display: inline-flex;
      align-items: center;
      font-size: 8rem; }
    .team-card__caption--text {
      font-size: 1.6rem;
      font-weight: 500; }
      .theme-light .team-card__caption--text {
        color: #141716; }
      .theme-dark .team-card__caption--text {
        color: #fff; }
    .team-card__caption--name {
      font-size: 1.6rem;
      font-weight: 600; }
      .theme-light .team-card__caption--name {
        color: #141716; }
      .theme-dark .team-card__caption--name {
        color: #fff; }
  .team-card__text {
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 48rem;
    border-radius: 1rem;
    transform: rotateY(180deg);
    transition: transform 0.4s ease-out;
    display: flex;
    align-items: center;
    padding: 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 130%;
    overflow: hidden; }
    .theme-light .team-card__text {
      background-color: #d2dcdf; }
    .theme-dark .team-card__text {
      background-color: #141716; }
    .theme-light .team-card__text {
      box-shadow: 0 1rem 2rem rgba(8, 9, 10, 0.25); }
    .theme-dark .team-card__text {
      box-shadow: 0 1rem 2rem rgba(4, 11, 29, 0.35); }
    .theme-light .team-card__text {
      color: #141716; }
    .theme-dark .team-card__text {
      color: #fff; }
    .team-card__text-enter {
      transform: rotateY(180deg); }
    .team-card__text-enter-active {
      transform: rotateY(0); }
    .team-card__text-exit, .team-card__text-enter-done {
      transform: rotateY(0); }
    .team-card__text-exit-active {
      transform: rotateY(180deg); }

.ql-clipboard {
  display: none; }

.text-editor__editor {
  padding: 2rem;
  font-weight: 400;
  font-size: 1.6rem;
  word-wrap: break-word;
  word-break: break-word; }
  .theme-light .text-editor__editor {
    color: #141716; }
  .theme-dark .text-editor__editor {
    color: #fff; }

.text-editor__toolbar {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  z-index: 10;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 8rem;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  padding: 2rem; }
  .theme-light .text-editor__toolbar {
    border-bottom-color: #d2dcdf; }
  .theme-dark .text-editor__toolbar {
    border-bottom-color: #141716; }
  .theme-light .text-editor__toolbar {
    background-color: #fff; }
  .theme-dark .text-editor__toolbar {
    background-color: #08090a; }

.text-editor__file-input {
  display: none; }

.text-editor__btn {
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 2rem;
  transition: color 0.2s;
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
  .theme-light .text-editor__btn {
    color: #141716; }
  .theme-dark .text-editor__btn {
    color: #fff; }
  @media not all and (pointer: coarse) {
    .theme-light .text-editor__btn:hover {
      color: #0092db; }
    .theme-dark .text-editor__btn:hover {
      color: #0092db; } }
  .theme-light .text-editor__btn--active {
    color: #0092db; }
  .theme-dark .text-editor__btn--active {
    color: #0092db; }
  .text-editor__btn--small {
    font-size: 1.5rem; }
  .text-editor__btn--big {
    font-size: 2.5rem; }
  .text-editor__btn--green {
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    .theme-light .text-editor__btn--green {
      color: #1db954; }
    .theme-dark .text-editor__btn--green {
      color: #1db954; }
    @media not all and (pointer: coarse) {
      .theme-light .text-editor__btn--green:hover {
        color: #1db954; }
      .theme-dark .text-editor__btn--green:hover {
        color: #1db954; } }

.text-editor__modal {
  display: flex;
  flex-direction: column; }
  .text-editor__modal > * {
    margin-bottom: 2rem; }

.text-editor__modal-header {
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
  font-weight: 600; }
  .theme-light .text-editor__modal-header {
    background-color: #0092db; }
  .theme-dark .text-editor__modal-header {
    background-color: #0092db; }

.text-editor__progress-bar {
  width: 100%;
  height: 3rem;
  border-radius: 100rem;
  overflow: hidden;
  position: relative; }
  .theme-light .text-editor__progress-bar {
    background-color: #d2dcdf; }
  .theme-dark .text-editor__progress-bar {
    background-color: #141716; }

.text-editor__progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%; }
  .theme-light .text-editor__progress {
    background-color: #1db954; }
  .theme-dark .text-editor__progress {
    background-color: #1db954; }

.quill__bold {
  font-weight: 700; }

.quill__italic {
  font-style: italic; }

.quill__blockquote {
  border-left-width: 0.5rem;
  border-left-style: solid;
  padding-left: 1rem; }
  .theme-light .quill__blockquote {
    border-left-color: #383838; }
  .theme-dark .quill__blockquote {
    border-left-color: #d0d0d0; }

.quill__list {
  margin-left: 5rem; }
  .quill__list li::marker {
    font-weight: 600; }

.quill__h1 {
  font-size: 3.2rem;
  font-weight: 700; }

.quill__h2 {
  font-size: 2.5rem;
  font-weight: 700; }

.quill__video-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; }

.quill__video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.quill__audio-wrapper {
  width: 100%;
  display: flex;
  justify-content: center; }

.quill__audio {
  width: 70%; }
  @media (max-width: 43.75em) {
    .quill__audio {
      width: 100%; } }

.quill__image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center; }

.quill__image {
  width: 70%; }
  @media (max-width: 43.75em) {
    .quill__image {
      width: 100%; } }

.bottombar {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; }
  .bottombar__section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
    overflow-x: auto;
    align-items: center;
    justify-items: center; }
    .theme-light .bottombar__section {
      background-color: #fff; }
    .theme-dark .bottombar__section {
      background-color: #08090a; }
    .bottombar__section--1-enter {
      transform: translateX(-100%); }
    .bottombar__section--1-enter-active {
      transform: translateX(0);
      transition: all 0.4s; }
    .bottombar__section--1-exit {
      transform: translateX(0); }
    .bottombar__section--1-exit-active {
      transform: translateX(-100%);
      transition: all 0.4s; }
    .bottombar__section--2-enter {
      transform: translateX(100%); }
    .bottombar__section--2-enter-active {
      transform: translateX(0);
      transition: all 0.4s; }
    .bottombar__section--2-exit {
      transform: translateX(0); }
    .bottombar__section--2-exit-active {
      transform: translateX(100%);
      transition: all 0.4s; }
  .bottombar::after {
    left: 2rem;
    top: 0;
    content: "";
    position: absolute;
    width: calc(100% - 4rem);
    height: 2px; }
    .theme-light .bottombar::after {
      background-image: linear-gradient(to bottom, #d0d0d0, transparent); }
    .theme-dark .bottombar::after {
      background-image: linear-gradient(to bottom, #383838, transparent); }
    @media (max-width: 31.25em) {
      .bottombar::after {
        width: 100%;
        left: 0; } }
  .bottombar--fullborder::after {
    left: 0;
    width: 100%; }
  .bottombar__item {
    background-color: transparent;
    display: flex;
    height: 100%; }
  .bottombar__btn-icon {
    height: 100%;
    background: transparent;
    display: flex;
    padding: 1rem;
    align-items: center; }
    .theme-light .bottombar__btn-icon {
      color: #141716; }
    .theme-dark .bottombar__btn-icon {
      color: #fff; }
    .bottombar__btn-icon svg {
      width: 2rem; }
      .theme-light .bottombar__btn-icon svg {
        fill: #141716; }
      .theme-dark .bottombar__btn-icon svg {
        fill: #fff; }
    .theme-light .bottombar__btn-icon--active {
      color: #0092db; }
    .theme-dark .bottombar__btn-icon--active {
      color: #0092db; }
    .theme-light .bottombar__btn-icon--active svg {
      fill: #0092db; }
    .theme-dark .bottombar__btn-icon--active svg {
      fill: #0092db; }

.container {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 144rem;
  min-height: 100vh;
  margin: 0 auto;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem; }
  .container--3c {
    grid-template-rows: [row-navbar-start] 8rem [row-navbar-end row-main-start] 1fr [row-main-end row-grid-end];
    grid-template-columns: [padding-left] 2rem [col-sidebar-left-start col-navbar-start] minmax(20rem, 1fr) [col-sidebar-left-end col-main-start] minmax(-webkit-min-content, 3fr) [col-main-end col-sidebar-right-start] minmax(20rem, 1fr) [col-sidebar-right-end col-navbar-end col-grid-end] 2rem [padding-right];
    grid-template-columns: [padding-left] 2rem [col-sidebar-left-start col-navbar-start] minmax(20rem, 1fr) [col-sidebar-left-end col-main-start] minmax(min-content, 3fr) [col-main-end col-sidebar-right-start] minmax(20rem, 1fr) [col-sidebar-right-end col-navbar-end col-grid-end] 2rem [padding-right]; }
    @media (max-width: 56.25em) {
      .container--3c {
        grid-template-rows: [row-navbar-start] 8rem [row-navbar-end row-main-start] 1fr [row-main-end row-bottombar-start] 8rem [row-bottombar-end row-grid-end];
        grid-template-columns: [padding-left col-navbar-start] 4rem [col-main-start] minmax(-webkit-min-content, 1fr) [col-main-end] 4rem [padding-right col-navbar-end col-grid-end];
        grid-template-columns: [padding-left col-navbar-start] 4rem [col-main-start] minmax(min-content, 1fr) [col-main-end] 4rem [padding-right col-navbar-end col-grid-end]; } }
    @media (max-width: 31.25em) {
      .container--3c {
        grid-template-rows: [row-navbar-start] 8rem [row-navbar-end row-main-start] 1fr [row-main-end row-bottombar-start] 8rem [row-bottombar-end row-grid-end];
        grid-template-columns: [col-sidebar-left-end col-main-start] minmax(-webkit-min-content, 1fr) [col-main-end col-grid-end];
        grid-template-columns: [col-sidebar-left-end col-main-start] minmax(min-content, 1fr) [col-main-end col-grid-end]; } }
  .container--2c {
    grid-template-rows: [row-navbar-start] 8rem [row-navbar-end row-main-start] 1fr [row-main-end row-grid-end];
    grid-template-columns: [padding-left] 2rem [col-sidebar-left-start col-navbar-start] minmax(20rem, 1fr) [col-sidebar-left-end col-main-start] minmax(-webkit-min-content, 4fr) [col-main-end col-grid-end] 2rem [padding-right];
    grid-template-columns: [padding-left] 2rem [col-sidebar-left-start col-navbar-start] minmax(20rem, 1fr) [col-sidebar-left-end col-main-start] minmax(min-content, 4fr) [col-main-end col-grid-end] 2rem [padding-right]; }
    @media (max-width: 56.25em) {
      .container--2c {
        grid-template-rows: [row-navbar-start] 8rem [row-navbar-end row-main-start] 1fr [row-main-end row-bottombar-start] 8rem [row-bottombar-end row-grid-end];
        grid-template-columns: [padding-left col-navbar-start] 4rem [col-main-start] minmax(-webkit-min-content, 1fr) [col-main-end] 4rem [padding-right col-navbar-end col-grid-end];
        grid-template-columns: [padding-left col-navbar-start] 4rem [col-main-start] minmax(min-content, 1fr) [col-main-end] 4rem [padding-right col-navbar-end col-grid-end]; } }
    @media (max-width: 31.25em) {
      .container--2c {
        grid-template-rows: [row-navbar-start] 8rem [row-navbar-end row-main-start] 1fr [row-main-end row-bottombar-start] 8rem [row-bottombar-end row-grid-end];
        grid-template-columns: [col-sidebar-left-end col-main-start] minmax(-webkit-min-content, 1fr) [col-main-end col-grid-end];
        grid-template-columns: [col-sidebar-left-end col-main-start] minmax(min-content, 1fr) [col-main-end col-grid-end]; } }
  .container--1c {
    grid-template-rows: [row-navbar-start] 8rem [row-navbar-end row-main-start] 1fr [row-main-end row-grid-end];
    grid-template-columns: [padding-left] 4rem [col-navbar-start col-main-start] 1fr [col-main-end col-grid-end] 4rem [padding-right]; }
    @media (max-width: 31.25em) {
      .container--1c {
        grid-template-rows: [row-navbar-start] 8rem [row-navbar-end row-main-start] 1fr [row-main-end row-grid-end];
        grid-template-columns: [col-sidebar-left-end col-main-start] minmax(-webkit-min-content, 1fr) [col-main-end col-grid-end];
        grid-template-columns: [col-sidebar-left-end col-main-start] minmax(min-content, 1fr) [col-main-end col-grid-end]; } }
  .container__navbar {
    transform: translate3d(0, 0, 0);
    z-index: 100;
    position: fixed;
    top: 0;
    left: 50%;
    height: 8rem;
    width: 100vw;
    padding: 0 2rem;
    max-width: 144rem;
    transform: translateX(-50%);
    grid-row: row-navbar-start / row-navbar-end;
    grid-column: col-navbar-start / col-navbar-end;
    display: flex;
    justify-content: center; }
    @media (max-width: 31.25em) {
      .container__navbar {
        padding: 0; } }
  .container__main {
    position: relative;
    grid-row: row-main-start / row-main-end;
    grid-column: col-main-start / col-main-end; }
  .container__sidebar {
    z-index: 100;
    transform: translate3d(0, 0, 0);
    position: -webkit-sticky;
    position: sticky;
    top: 8rem;
    width: 100%;
    height: calc(100vh - 8rem);
    max-height: 100rem;
    overflow-y: hidden; }
    .container__sidebar--left {
      left: 0;
      grid-row: row-main-start / row-grid-end;
      grid-column: col-sidebar-left-start / col-sidebar-left-end; }
      @media (max-width: 56.25em) {
        .container__sidebar--left {
          display: none; } }
    .container__sidebar--right {
      right: 0;
      grid-row: row-main-start / row-grid-end;
      grid-column: col-sidebar-right-start / col-sidebar-right-end; }
      @media (max-width: 56.25em) {
        .container__sidebar--right {
          display: none; } }
  .container__bottombar {
    grid-row: row-bottombar-start / row-bottombar-end;
    grid-column: col-navbar-start / col-navbar-end;
    height: 8rem;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 200;
    display: none;
    transform: translate3d(0, 0, 0); }
    @media (max-width: 56.25em) {
      .container__bottombar {
        display: flex; } }

/*
  transform: translate3d(0, 0, 0) fixes a scroll bug of fixed and sticky elements on mobile devices
  no idea why this works
  https://stackoverflow.com/questions/44679794/position-fixed-on-chrome-mobile-causing-element-to-move-on-scroll-up-down
*/
.dashboard {
  display: grid;
  padding: 0 6rem 2rem 6rem;
  width: 100%;
  grid-template-rows: [row-header-start] 7.5rem [row-header-end];
  grid-template-columns: 1fr;
  grid-auto-rows: 8.5rem; }
  .dashboard--min-content {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content; }
  .dashboard > *:first-child {
    border-top: none !important; }
  .dashboard > *:last-child {
    border-bottom: none !important; }
  .dashboard--header > *:nth-child(2) {
    border-top: none !important; }
  @media (max-width: 56.25em) {
    .dashboard {
      padding: 0 2rem; } }
  @media (max-width: 43.75em) {
    .dashboard {
      padding: 0; } }
  .dashboard--min-content-item {
    justify-self: center; }
  .dashboard__header {
    grid-row: row-header-start / row-header-end;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    display: flex;
    overflow-x: auto;
    align-items: center;
    padding: 0 3rem;
    position: -webkit-sticky;
    position: sticky;
    top: 8rem;
    z-index: 10; }
    .theme-light .dashboard__header {
      background-color: #0092db; }
    .theme-dark .dashboard__header {
      background-color: #0092db; }
    .dashboard__header::-webkit-scrollbar {
      height: 0;
      width: 0; }
    @media (max-width: 31.25em) {
      .dashboard__header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; } }
    .dashboard__header > *:not(:last-child) {
      margin-right: 5rem; }
      @media (max-width: 31.25em) {
        .dashboard__header > *:not(:last-child) {
          margin-right: 2.5rem; } }
    .dashboard__header > *:last-child::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 3rem;
      right: -3rem;
      top: 0; }
  .dashboard__header-option {
    font-weight: 500;
    position: relative;
    font-size: 1.5rem;
    color: #fff;
    white-space: nowrap;
    align-items: center;
    padding: 0.5rem 0;
    background: transparent;
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    .dashboard__header-option::after {
      content: "";
      width: 0%;
      opacity: 0;
      visibility: hidden;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #fff;
      transition: all 0.4s; }
    @media not all and (pointer: coarse) {
      .dashboard__header-option:hover::after {
        width: 100%;
        opacity: 1;
        visibility: visible; } }
    .dashboard__header-option--active::after {
      width: 100%;
      opacity: 1;
      visibility: visible; }
  .dashboard__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 3.5rem 0.5rem 5rem;
    background-color: transparent;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid; }
    .theme-light .dashboard__item {
      border-color: #d2dcdf; }
    .theme-dark .dashboard__item {
      border-color: #141716; }
    @media (max-width: 31.25em) {
      .dashboard__item {
        padding: 0.5rem 2rem 0.5rem 2.8rem; } }
    .dashboard__item--min-content {
      height: 8.5rem; }
    .dashboard__item--warning {
      justify-content: center;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 600;
      background-position: 0%;
      background-size: 300% 200%;
      background-image: linear-gradient(120deg, transparent 0% 50%, #d00000 50%);
      transition: background 0.7s 0.2s, color 0.3s 0.4s;
      /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
      .theme-light .dashboard__item--warning {
        color: #d00000; }
      .theme-dark .dashboard__item--warning {
        color: #d00000; }
      .theme-light .dashboard__item--warning {
        border-color: #d00000; }
      .theme-dark .dashboard__item--warning {
        border-color: #d00000; }
      @media not all and (pointer: coarse) {
        .dashboard__item--warning:hover {
          background-position: 100%;
          color: #fff; } }
      @media (max-width: 31.25em) {
        .dashboard__item--warning {
          font-size: 2rem; } }
  .dashboard__item-section {
    display: flex;
    flex-direction: column; }
    .dashboard__item-section--primary {
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      font-size: 1.6rem; }
      .theme-light .dashboard__item-section--primary {
        color: #141716; }
      .theme-dark .dashboard__item-section--primary {
        color: #fff; }
      @media (max-width: 31.25em) {
        .dashboard__item-section--primary {
          font-size: 1.6rem; } }
    .dashboard__item-section--secondary {
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      font-size: 1.4rem; }
      .theme-light .dashboard__item-section--secondary {
        color: #383838; }
      .theme-dark .dashboard__item-section--secondary {
        color: #d0d0d0; }
      @media (max-width: 31.25em) {
        .dashboard__item-section--secondary {
          font-size: 1.3rem; } }
    .dashboard__item-section--tertiary {
      display: inline-flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 400; }
      .theme-light .dashboard__item-section--tertiary {
        color: #383838; }
      .theme-dark .dashboard__item-section--tertiary {
        color: #d0d0d0; }
      @media (max-width: 31.25em) {
        .dashboard__item-section--tertiary {
          font-size: 1.3rem; } }
    .theme-light .dashboard__item-section--active {
      color: #0092db; }
    .theme-dark .dashboard__item-section--active {
      color: #0092db; }
    .dashboard__item-section--phone {
      display: none; }
      @media (max-width: 43.75em) {
        .dashboard__item-section--phone {
          display: flex; } }
    .dashboard__item-section--normal {
      display: flex; }
      @media (max-width: 43.75em) {
        .dashboard__item-section--normal {
          display: none; } }
  .dashboard__item-btn-icon {
    position: relative;
    display: inline-flex;
    align-items: center;
    background: transparent;
    font-size: 2.5rem;
    transition: color 0.3s;
    cursor: pointer;
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    .theme-light .dashboard__item-btn-icon {
      color: #141716; }
    .theme-dark .dashboard__item-btn-icon {
      color: #fff; }
    .dashboard__item-btn-icon > svg {
      width: 2.5rem;
      transition: fill 0.3s; }
      .theme-light .dashboard__item-btn-icon > svg {
        fill: #141716; }
      .theme-dark .dashboard__item-btn-icon > svg {
        fill: #fff; }
    @media not all and (pointer: coarse) {
      .theme-light .dashboard__item-btn-icon:hover {
        color: #0092db; }
      .theme-dark .dashboard__item-btn-icon:hover {
        color: #0092db; }
      .theme-light .dashboard__item-btn-icon:hover > svg {
        fill: #0092db; }
      .theme-dark .dashboard__item-btn-icon:hover > svg {
        fill: #0092db; } }
    .theme-light .dashboard__item-btn-icon--active {
      color: #0092db; }
    .theme-dark .dashboard__item-btn-icon--active {
      color: #0092db; }
    .theme-light .dashboard__item-btn-icon--active > svg {
      fill: #0092db; }
    .theme-dark .dashboard__item-btn-icon--active > svg {
      fill: #0092db; }
  .dashboard__option {
    padding: 2rem 4rem;
    font-size: 2rem;
    font-weight: 400;
    background: transparent;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    .theme-light .dashboard__option {
      color: #383838; }
    .theme-dark .dashboard__option {
      color: #d0d0d0; }
    @media not all and (pointer: coarse) {
      .theme-light .dashboard__option:hover {
        color: #0092db; }
      .theme-dark .dashboard__option:hover {
        color: #0092db; } }
    .dashboard__option > *:not(:last-child) {
      margin-right: 2rem; }
    .dashboard__option:not(:last-child) {
      border-bottom-style: solid;
      border-bottom-width: 1px; }
      .theme-light .dashboard__option:not(:last-child) {
        border-bottom-color: #7a7a7a; }
      .theme-dark .dashboard__option:not(:last-child) {
        border-bottom-color: #d0d0d0; }

.dashboard-form {
  display: grid;
  grid-column: 1fr;
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
  padding: 2rem 6rem; }
  @media (max-width: 43.75em) {
    .dashboard-form {
      padding: 2rem 2rem; } }
  .dashboard-form__file-input {
    display: none; }
  .dashboard-form__user-picture-label {
    display: inline-flex;
    align-items: center; }
    .dashboard-form__user-picture-label > *:not(:last-child) {
      margin-right: 2rem; }
  .dashboard-form__toggle-btn {
    grid-column: col-input-start / col-input-end; }
    @media (max-width: 43.75em) {
      .dashboard-form__toggle-btn {
        grid-column: 1 / 2; } }
  .dashboard-form__back-btn {
    grid-column: col-input-start / col-input-end;
    justify-self: start;
    background: transparent;
    line-height: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-style: solid; }
    .dashboard-form__back-btn > *:not(:last-child) {
      margin-right: 1rem; }
    @media (max-width: 43.75em) {
      .dashboard-form__back-btn {
        grid-column: 1 / 2; } }
    .theme-light .dashboard-form__back-btn {
      color: #d00000; }
    .theme-dark .dashboard-form__back-btn {
      color: #d00000; }
    .theme-light .dashboard-form__back-btn {
      border-color: #d00000; }
    .theme-dark .dashboard-form__back-btn {
      border-color: #d00000; }
    .dashboard-form__back-btn--icon {
      font-size: 1.2rem; }
    .dashboard-form__back-btn--text {
      font-weight: 600;
      font-size: 1.5rem; }
  .dashboard-form__item {
    display: grid;
    max-width: 75rem;
    grid-template-columns: [col-label-start] minmax(-webkit-min-content, 1fr) [col-label-end col-input-start] minmax(-webkit-min-content, 3fr) [col-input-end];
    grid-template-columns: [col-label-start] minmax(min-content, 1fr) [col-label-end col-input-start] minmax(min-content, 3fr) [col-input-end];
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
            column-gap: 5rem; }
    @media (max-width: 43.75em) {
      .dashboard-form__item {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        -webkit-column-gap: 0;
                column-gap: 0; } }
  .dashboard-form__submit-btn {
    grid-column: col-input-start / col-input-end; }
    @media (max-width: 43.75em) {
      .dashboard-form__submit-btn {
        grid-column: 1 / 2; } }
  .dashboard-form__submit-btn {
    grid-column: col-input-start / col-input-end; }
    @media (max-width: 43.75em) {
      .dashboard-form__submit-btn {
        grid-column: 1 / 2; } }
  .dashboard-form__header {
    display: grid;
    align-items: center;
    max-width: 75rem;
    grid-template-rows: 1fr;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
            column-gap: 5rem;
    grid-template-columns: [col-picture-start] minmax(-webkit-min-content, 1fr) [col-picture-end col-user-start] minmax(-webkit-min-content, 3fr) [col-user-end];
    grid-template-columns: [col-picture-start] minmax(min-content, 1fr) [col-picture-end col-user-start] minmax(min-content, 3fr) [col-user-end];
    align-items: center; }
    @media (max-width: 43.75em) {
      .dashboard-form__header {
        grid-template-columns: repeat(2, minmax(5rem, -webkit-max-content));
        grid-template-columns: repeat(2, minmax(5rem, max-content));
        grid-column-gap: 2.5rem;
        -webkit-column-gap: 2.5rem;
                column-gap: 2.5rem; } }
  .dashboard-form__picture {
    justify-self: end;
    grid-column: 1 / 2;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    overflow: hidden; }
    .dashboard-form__picture img {
      object-fit: cover;
      object-position: center center;
      height: 100%;
      width: 100%; }
  .dashboard-form__user {
    justify-self: start;
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column; }
    .dashboard-form__user--primary {
      font-size: 2rem;
      font-weight: 500;
      background: transparent; }
      .theme-light .dashboard-form__user--primary {
        color: #141716; }
      .theme-dark .dashboard-form__user--primary {
        color: #fff; }
    .dashboard-form__user--secondary {
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer; }
      .theme-light .dashboard-form__user--secondary {
        color: #0092db; }
      .theme-dark .dashboard-form__user--secondary {
        color: #0092db; }
  .dashboard-form__input-btn {
    grid-column: col-input-start / col-input-end;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    justify-self: start;
    background: transparent; }
    .theme-light .dashboard-form__input-btn {
      color: #0092db; }
    .theme-dark .dashboard-form__input-btn {
      color: #0092db; }
    @media (max-width: 43.75em) {
      .dashboard-form__input-btn {
        grid-column: 1 / 2; } }
  .dashboard-form__cover {
    grid-column: col-input-start / col-input-end;
    max-height: 50rem;
    width: 100%;
    border-radius: 3px;
    height: 100%;
    min-height: 30rem;
    border-width: 1px;
    border-style: solid;
    display: flex;
    justify-content: center;
    position: relative; }
    .theme-light .dashboard-form__cover {
      border-color: rgba(208, 208, 208, 0.7); }
    .theme-dark .dashboard-form__cover {
      border-color: rgba(255, 255, 255, 0.2); }
    .dashboard-form__cover label {
      display: inline-flex;
      cursor: pointer;
      align-self: center; }
    .dashboard-form__cover--icon {
      font-size: 6rem; }
      .theme-light .dashboard-form__cover--icon {
        color: #1db954; }
      .theme-dark .dashboard-form__cover--icon {
        color: #1db954; }
    .theme-light .dashboard-form__cover--error {
      border-color: #d00000; }
    .theme-dark .dashboard-form__cover--error {
      border-color: #d00000; }
    @media (max-width: 43.75em) {
      .dashboard-form__cover {
        grid-column: 1 / 2; } }
    .dashboard-form__cover img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center; }
  .dashboard-form__change-cover-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 2rem;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    border-top-left-radius: 0.5rem; }
    .theme-light .dashboard-form__change-cover-btn {
      background-color: #0092db; }
    .theme-dark .dashboard-form__change-cover-btn {
      background-color: #0092db; }
  .dashboard-form__stuff-container {
    display: flex;
    flex-wrap: wrap;
    grid-column: col-input-start / col-input-end; }
    @media (max-width: 43.75em) {
      .dashboard-form__stuff-container {
        grid-column: 1 / 2; } }
    .dashboard-form__stuff-container > * {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem; }
      @media (max-width: 43.75em) {
        .dashboard-form__stuff-container > * {
          margin-right: 0.8rem;
          margin-bottom: 0.8rem; } }
  .dashboard-form__stuff {
    display: flex;
    cursor: pointer;
    border: 1px solid #0092db;
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 100rem;
    position: relative;
    padding: 0 0.5rem 0 4rem;
    word-wrap: break-word;
    word-break: break-word; }
    .theme-light .dashboard-form__stuff {
      color: #383838; }
    .theme-dark .dashboard-form__stuff {
      color: #d0d0d0; }
    @media (max-width: 43.75em) {
      .dashboard-form__stuff {
        padding: 0 0.5rem 0 4.5rem;
        font-size: 1.7rem; } }
  .dashboard-form__remove-stuff-btn {
    background-color: #0092db;
    height: 100%;
    border-top-left-radius: 100rem;
    border-bottom-left-radius: 100rem;
    width: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    font-size: 1.5rem;
    color: #fff;
    left: 0; }
    @media (max-width: 43.75em) {
      .dashboard-form__remove-stuff-btn {
        font-size: 1.6rem;
        width: 4rem; } }
  .dashboard-form__add-stuff-box {
    justify-self: start;
    width: 100%;
    grid-column: col-input-start / col-input-end;
    display: flex;
    align-items: stretch; }
    @media (max-width: 43.75em) {
      .dashboard-form__add-stuff-box {
        grid-column: 1 / 2; } }
    .dashboard-form__add-stuff-box input {
      font-size: 1.4rem;
      position: relative;
      border-width: 1px;
      resize: none;
      border-style: solid;
      resize: none;
      width: calc(100% - 5rem);
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      padding: 1rem;
      background: transparent; }
      .theme-light .dashboard-form__add-stuff-box input {
        border-color: rgba(208, 208, 208, 0.7); }
      .theme-dark .dashboard-form__add-stuff-box input {
        border-color: rgba(255, 255, 255, 0.2); }
      .theme-light .dashboard-form__add-stuff-box input {
        color: #383838; }
      .theme-dark .dashboard-form__add-stuff-box input {
        color: #d0d0d0; }
      .theme-light .dashboard-form__add-stuff-box input--error {
        border-color: #d00000; }
      .theme-dark .dashboard-form__add-stuff-box input--error {
        border-color: #d00000; }
      .dashboard-form__add-stuff-box input::-webkit-input-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1;
        /* Firefox */ }
      .dashboard-form__add-stuff-box input:-ms-input-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1;
        /* Firefox */ }
      .dashboard-form__add-stuff-box input::-ms-input-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1;
        /* Firefox */ }
      .dashboard-form__add-stuff-box input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1;
        /* Firefox */ }
        .theme-light .dashboard-form__add-stuff-box input::-webkit-input-placeholder {
          color: #383838; }
        .theme-light .dashboard-form__add-stuff-box input:-ms-input-placeholder {
          color: #383838; }
        .theme-light .dashboard-form__add-stuff-box input::-ms-input-placeholder {
          color: #383838; }
        .theme-light .dashboard-form__add-stuff-box input::placeholder {
          color: #383838; }
        .theme-dark .dashboard-form__add-stuff-box input::-webkit-input-placeholder {
          color: #d0d0d0; }
        .theme-dark .dashboard-form__add-stuff-box input:-ms-input-placeholder {
          color: #d0d0d0; }
        .theme-dark .dashboard-form__add-stuff-box input::-ms-input-placeholder {
          color: #d0d0d0; }
        .theme-dark .dashboard-form__add-stuff-box input::placeholder {
          color: #d0d0d0; }
      .dashboard-form__add-stuff-box input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */ }
        .theme-light .dashboard-form__add-stuff-box input:-ms-input-placeholder {
          color: #383838; }
        .theme-dark .dashboard-form__add-stuff-box input:-ms-input-placeholder {
          color: #d0d0d0; }
      .dashboard-form__add-stuff-box input::-ms-input-placeholder {
        /* Microsoft Edge */ }
        .theme-light .dashboard-form__add-stuff-box input::-ms-input-placeholder {
          color: #383838; }
        .theme-dark .dashboard-form__add-stuff-box input::-ms-input-placeholder {
          color: #d0d0d0; }
    .dashboard-form__add-stuff-box button {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5rem;
      font-size: 2rem;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px; }
      .theme-light .dashboard-form__add-stuff-box button {
        background-color: #0092db; }
      .theme-dark .dashboard-form__add-stuff-box button {
        background-color: #0092db; }
  .dashboard-form__input {
    justify-self: start;
    grid-column: col-input-start / col-input-end;
    font-size: 1.4rem;
    position: relative;
    border-width: 1px;
    resize: none;
    border-style: solid;
    resize: none;
    width: 100%;
    border-radius: 3px;
    padding: 1rem;
    background: transparent; }
    .theme-light .dashboard-form__input {
      border-color: rgba(208, 208, 208, 0.7); }
    .theme-dark .dashboard-form__input {
      border-color: rgba(255, 255, 255, 0.2); }
    .theme-light .dashboard-form__input {
      color: #383838; }
    .theme-dark .dashboard-form__input {
      color: #d0d0d0; }
    .theme-light .dashboard-form__input--error {
      border-color: #d00000; }
    .theme-dark .dashboard-form__input--error {
      border-color: #d00000; }
    @media (max-width: 43.75em) {
      .dashboard-form__input {
        grid-column: 1 / 2; } }
    .dashboard-form__input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .dashboard-form__input:-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .dashboard-form__input::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .dashboard-form__input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
      .theme-light .dashboard-form__input::-webkit-input-placeholder {
        color: #383838; }
      .theme-light .dashboard-form__input:-ms-input-placeholder {
        color: #383838; }
      .theme-light .dashboard-form__input::-ms-input-placeholder {
        color: #383838; }
      .theme-light .dashboard-form__input::placeholder {
        color: #383838; }
      .theme-dark .dashboard-form__input::-webkit-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .dashboard-form__input:-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .dashboard-form__input::-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .dashboard-form__input::placeholder {
        color: #d0d0d0; }
    .dashboard-form__input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */ }
      .theme-light .dashboard-form__input:-ms-input-placeholder {
        color: #383838; }
      .theme-dark .dashboard-form__input:-ms-input-placeholder {
        color: #d0d0d0; }
    .dashboard-form__input::-ms-input-placeholder {
      /* Microsoft Edge */ }
      .theme-light .dashboard-form__input::-ms-input-placeholder {
        color: #383838; }
      .theme-dark .dashboard-form__input::-ms-input-placeholder {
        color: #d0d0d0; }
  .dashboard-form__error {
    font-size: 1.4rem;
    font-weight: 600;
    grid-column: col-input-start / col-input-end; }
    .theme-light .dashboard-form__error {
      color: #d00000; }
    .theme-dark .dashboard-form__error {
      color: #d00000; }
    @media (max-width: 43.75em) {
      .dashboard-form__error {
        grid-column: 1 / 2; } }
  .dashboard-form__warning {
    justify-self: start;
    grid-column: col-input-start / col-input-end;
    font-size: 1.6rem;
    font-weight: 600;
    position: relative;
    border-width: 2px;
    border-style: solid;
    resize: none;
    width: 100%;
    border-radius: 3px;
    padding: 1rem;
    background: transparent; }
    .theme-light .dashboard-form__warning--red {
      border-color: #d00000; }
    .theme-dark .dashboard-form__warning--red {
      border-color: #d00000; }
    .theme-light .dashboard-form__warning--red {
      color: #d00000; }
    .theme-dark .dashboard-form__warning--red {
      color: #d00000; }
    .theme-light .dashboard-form__warning--green {
      border-color: #1db954; }
    .theme-dark .dashboard-form__warning--green {
      border-color: #1db954; }
    .theme-light .dashboard-form__warning--green {
      color: #1db954; }
    .theme-dark .dashboard-form__warning--green {
      color: #1db954; }
    @media (max-width: 43.75em) {
      .dashboard-form__warning {
        grid-column: 1 / 2; } }
  .dashboard-form label {
    justify-self: end;
    text-align: center;
    grid-column: col-label-start / col-label-end;
    font-size: 1.6rem;
    font-weight: 600; }
    .theme-light .dashboard-form label {
      color: #383838; }
    .theme-dark .dashboard-form label {
      color: #d0d0d0; }
    @media (max-width: 43.75em) {
      .dashboard-form label {
        justify-self: start;
        grid-column: 1 / 2; } }
  .dashboard-form__item-description {
    grid-column: col-input-start / col-input-end;
    font-size: 1.3rem;
    font-weight: 500; }
    .theme-light .dashboard-form__item-description {
      color: #8c8c8c; }
    .theme-dark .dashboard-form__item-description {
      color: #7a7a7a; }
    @media (max-width: 43.75em) {
      .dashboard-form__item-description {
        grid-column: 1 / 2;
        font-size: 1.5rem; } }

.feed {
  display: grid;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  grid-template-columns: 1fr;
  padding: 2rem 0;
  min-height: 100%; }
  .feed--filters {
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content; }
  @media (max-width: 31.25em) {
    .feed {
      padding: 0;
      grid-row-gap: 0;
      row-gap: 0; } }
  .feed__loading {
    padding: 2rem;
    display: flex;
    justify-content: center;
    overflow: hidden; }
    .feed__loading--enter {
      opacity: 0; }
    .feed__loading--enter-active {
      opacity: 1;
      transition: all 0.3s; }
    .feed__loading--exit {
      opacity: 1; }
    .feed__loading--exit-active {
      opacity: 0;
      transition: all 0.3s; }

.filters__container {
  position: relative;
  z-index: 50;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem; }
  @media (max-width: 43.75em) {
    .filters__container {
      grid-gap: 0.8rem;
      gap: 0.8rem; } }
  @media (max-width: 31.25em) {
    .filters__container {
      padding: 2rem; } }

.filters__category {
  display: flex;
  cursor: default;
  border: 1px solid #0092db;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 100rem;
  position: relative;
  padding: 0 0.5rem 0 4rem;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden; }
  .theme-light .filters__category {
    color: #383838; }
  .theme-dark .filters__category {
    color: #d0d0d0; }
  @media (max-width: 43.75em) {
    .filters__category {
      font-size: 1.7rem;
      padding: 0 0.5rem 0 4.5rem; } }

.filters__remove-category-btn {
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 100rem;
  border-bottom-left-radius: 100rem;
  background-color: #0092db;
  height: 100%;
  width: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #fff; }
  @media (max-width: 43.75em) {
    .filters__remove-category-btn {
      font-size: 1.6rem;
      width: 4rem; } }

.footer {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 4rem;
  background-image: linear-gradient(to right bottom, #009eed 0 20%, #006ea5 80%); }
  @media (max-width: 56.25em) {
    .footer {
      padding: 22rem 4rem 12rem 4rem; } }
  @media (max-width: 31.25em) {
    .footer {
      padding: 26rem 4rem 16rem 4rem; } }
  .footer--bottombar {
    min-height: calc(100vh - 8rem); }
  .footer__scroll-up-btn {
    display: flex;
    background: transparent;
    position: absolute;
    top: 4rem;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 3.5rem; }
    @media (max-width: 43.75em) {
      .footer__scroll-up-btn {
        display: none; } }
  .footer__heading {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 10rem;
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    right: 2rem;
    top: 0;
    display: flex;
    flex-direction: column;
    line-height: 80%;
    transform: rotateZ(-30deg); }
    @media (max-width: 56.25em) {
      .footer__heading {
        font-size: 7rem; } }
    .footer__heading--1 {
      transform: skewX(-25deg); }
    .footer__heading--2 {
      transform: skewX(25deg); }
  .footer__bottom {
    border-top: 2px solid #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3rem 4rem;
    display: grid;
    justify-content: end;
    grid-template-columns: repeat(auto-fit, minmax(2.7rem, -webkit-min-content));
    grid-template-columns: repeat(auto-fit, minmax(2.7rem, min-content));
    align-items: center;
    grid-gap: 3rem;
    gap: 3rem;
    min-width: 50%;
    max-width: 100%; }
    @media (max-width: 56.25em) {
      .footer__bottom {
        min-width: 65%; } }
    @media (max-width: 31.25em) {
      .footer__bottom {
        justify-content: center; } }
  .footer__social-link {
    font-size: 2.7rem;
    color: #fff;
    display: flex; }
  .footer__form {
    flex-direction: column;
    display: flex;
    width: 100%;
    max-width: 40rem;
    grid-gap: 2rem;
    gap: 2rem; }
    .footer__form--heading {
      font-weight: 600;
      font-size: 2rem;
      color: #fff;
      line-height: 140%; }
    .footer__form button {
      border: 2px solid #fff;
      padding: 1rem;
      display: flex;
      justify-content: center;
      width: 100%;
      color: #fff;
      font-weight: 600;
      font-size: 1.6rem;
      background: transparent; }
  .footer__form-input {
    font-size: 1.6rem;
    font-weight: 500;
    position: relative;
    border: 2px solid #fff;
    width: 100%;
    padding: 1rem;
    color: #fff;
    background: transparent; }
    .footer__form-input--error {
      border: 2px solid #d00000 !important; }
    .footer__form-input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
      font-weight: 500;
      opacity: 1;
      /* Firefox */ }
    .footer__form-input:-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
      font-weight: 500;
      opacity: 1;
      /* Firefox */ }
    .footer__form-input::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
      font-weight: 500;
      opacity: 1;
      /* Firefox */ }
    .footer__form-input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
      font-weight: 500;
      opacity: 1;
      /* Firefox */ }
    .footer__form-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
      font-weight: 500; }
    .footer__form-input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #fff;
      font-weight: 500; }
  .footer__form-error {
    font-size: 1.4rem;
    font-weight: 600; }
    .theme-light .footer__form-error {
      color: #d00000; }
    .theme-dark .footer__form-error {
      color: #d00000; }

.fullpage-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: [row-header-start] -webkit-min-content [row-header-end row-form-start] -webkit-min-content [row-form-end row-footer-start] 8rem [row-footer-end];
  grid-template-rows: [row-header-start] min-content [row-header-end row-form-start] min-content [row-form-end row-footer-start] 8rem [row-footer-end]; }
  .fullpage-form__header {
    grid-row: row-header-start / row-header-end; }
  .fullpage-form__footer {
    grid-row: row-footer-start / row-footer-end;
    position: fixed;
    width: 100%;
    height: 8rem;
    bottom: 0;
    left: 0; }
  .fullpage-form__form {
    grid-row: row-form-start / row-form-end;
    display: flex;
    justify-content: center; }

.hidden-sidebar {
  display: none;
  grid-template-rows: [row-close-btn-start] 8rem [row-close-btn-end] repeat(2, minmax(-webkit-min-content, 1fr));
  grid-template-rows: [row-close-btn-start] 8rem [row-close-btn-end] repeat(2, minmax(min-content, 1fr));
  grid-template-columns: 1fr;
  position: fixed;
  width: 100%;
  overflow: auto;
  height: 100%;
  z-index: 500;
  left: 0;
  top: 0; }
  @media (max-width: 56.25em) {
    .hidden-sidebar {
      display: grid; } }
  .theme-light .hidden-sidebar {
    background-color: #fff; }
  .theme-dark .hidden-sidebar {
    background-color: #08090a; }
  .hidden-sidebar-enter {
    transform: translateX(100%); }
  .hidden-sidebar-enter-active {
    transform: translateX(0);
    transition: transform 0.2s ease-out 0.2s; }
  .hidden-sidebar-enter-done, .hidden-sidebar-exit {
    transform: translateX(0); }
  .hidden-sidebar-exit-active {
    transform: translateX(100%);
    transition: transform 0.2s ease-in 0.2s; }
  .hidden-sidebar__close-btn {
    position: relative;
    width: 4rem;
    height: 3px;
    border-radius: 100rem;
    transform: rotateZ(45deg); }
    .theme-light .hidden-sidebar__close-btn {
      background-color: #0092db; }
    .theme-dark .hidden-sidebar__close-btn {
      background-color: #0092db; }
    .hidden-sidebar__close-btn::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 4rem;
      border-radius: 100rem;
      left: 0;
      top: 0;
      transform: rotateZ(-90deg); }
      .theme-light .hidden-sidebar__close-btn::before {
        background-color: #0092db; }
      .theme-dark .hidden-sidebar__close-btn::before {
        background-color: #0092db; }
  .hidden-sidebar__header {
    grid-row: row-close-btn-start / row-close-btn-end; }
  .hidden-sidebar__item {
    position: relative;
    display: flex;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 4rem;
    flex-direction: column; }
    .hidden-sidebar__item > * {
      display: flex; }
    .hidden-sidebar__item--icon {
      color: #fff;
      font-size: 4rem; }
    .hidden-sidebar__item--text-primary {
      color: #fff;
      font-weight: 500;
      font-size: 3.8rem; }
    .hidden-sidebar__item--text-secondary {
      color: #fff;
      font-size: 2.1rem;
      font-weight: 300; }
  .hidden-sidebar__item-btn {
    position: absolute;
    right: 4rem;
    bottom: 4rem;
    color: #fff;
    font-size: 2.5rem; }
    @media (max-width: 22rem) {
      .hidden-sidebar__item-btn {
        display: none; } }
  .hidden-sidebar__login-box {
    grid-row: 2 / 3;
    background-color: #0092db; }
  .hidden-sidebar__register-box {
    grid-row: 3 / 4;
    background-color: #006ea5; }
  .hidden-sidebar__close-btn-box {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 8rem;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; }
    .hidden-sidebar__close-btn-box-enter, .hidden-sidebar__close-btn-box-appear {
      opacity: 0; }
    .hidden-sidebar__close-btn-box-enter-active, .hidden-sidebar__close-btn-box-appear-active {
      opacity: 1;
      transition: opacity 0.4s 0.4s; }
    .hidden-sidebar__close-btn-box-enter-done, .hidden-sidebar__close-btn-box-appear-done, .hidden-sidebar__close-btn-box-exit {
      opacity: 1; }
    .hidden-sidebar__close-btn-box-exit-active {
      opacity: 0;
      transition: opacity 0.4s 0.4s; }

.navbar {
  position: relative;
  width: 100%;
  height: calc(100% - 1px);
  max-width: 144rem;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  display: grid;
  grid-template-rows: [row-navbar-start] 1fr [row-navbar-end];
  grid-template-columns: [padding-left] 1.5rem [col-navbar-left-start] minmax(-webkit-min-content, 1fr) [col-navbar-left-end col-navbar-center-start] minmax(-webkit-min-content, 3fr) [col-navbar-center-end col-navbar-right-start] minmax(-webkit-min-content, 1fr) [col-navbar-right-end col-navbar-end] 1.5rem [padding-right];
  grid-template-columns: [padding-left] 1.5rem [col-navbar-left-start] minmax(min-content, 1fr) [col-navbar-left-end col-navbar-center-start] minmax(min-content, 3fr) [col-navbar-center-end col-navbar-right-start] minmax(min-content, 1fr) [col-navbar-right-end col-navbar-end] 1.5rem [padding-right]; }
  .theme-light .navbar {
    background-color: #fff; }
  .theme-dark .navbar {
    background-color: #08090a; }
  @media (max-width: 56.25em) {
    .navbar {
      grid-template-columns: [padding-left] 1.5rem [col-navbar-left-start] -webkit-min-content [col-navbar-left-end col-navbar-right-start] 1fr [col-navbar-right-end col-navbar-end] 1.5rem [padding-right];
      grid-template-columns: [padding-left] 1.5rem [col-navbar-left-start] min-content [col-navbar-left-end col-navbar-right-start] 1fr [col-navbar-right-end col-navbar-end] 1.5rem [padding-right]; } }
  .navbar::after {
    left: 0;
    bottom: -2px;
    content: "";
    position: absolute;
    width: 100%;
    height: 2px; }
    .theme-light .navbar::after {
      background-image: linear-gradient(to bottom, #d0d0d0, transparent); }
    .theme-dark .navbar::after {
      background-image: linear-gradient(to bottom, #383838, transparent); }
  .navbar__menu-box {
    grid-row: row-navbar-start / row-navbar-end;
    grid-column: col-navbar-left-start / col-navbar-left-end;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .navbar__menu-box > *:not(:last-child) {
      margin-right: 4rem; }
      @media (max-width: 56.25em) {
        .navbar__menu-box > *:not(:last-child) {
          margin-right: 0; } }
  .navbar__profile-box {
    grid-row: row-navbar-start / row-navbar-end;
    grid-column: col-navbar-left-start / col-navbar-left-end;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    display: none; }
    .navbar__profile-box > *:not(:last-child) {
      margin-right: 4rem; }
      @media (max-width: 56.25em) {
        .navbar__profile-box > *:not(:last-child) {
          margin-right: 0; } }
    @media (max-width: 56.25em) {
      .navbar__profile-box {
        display: flex; } }
  .navbar__menu-btn {
    position: relative;
    width: 3rem;
    height: 3px;
    border-radius: 100rem; }
    .theme-light .navbar__menu-btn {
      background-color: #0092db; }
    .theme-dark .navbar__menu-btn {
      background-color: #0092db; }
    .navbar__menu-btn::before, .navbar__menu-btn::after {
      content: "";
      position: absolute;
      height: 100%;
      border-radius: 100rem;
      left: 0; }
      .theme-light .navbar__menu-btn::before, .theme-light .navbar__menu-btn::after {
        background-color: #0092db; }
      .theme-dark .navbar__menu-btn::before, .theme-dark .navbar__menu-btn::after {
        background-color: #0092db; }
    .navbar__menu-btn::before {
      width: 2rem;
      top: -1rem; }
    .navbar__menu-btn::after {
      width: 4rem;
      top: 1rem; }
    .navbar__menu-btn-enter {
      width: 3rem; }
      .navbar__menu-btn-enter::before {
        width: 2rem; }
      .navbar__menu-btn-enter::after {
        width: 4rem; }
    .navbar__menu-btn-enter-active {
      width: 0;
      transition: all 0.5s; }
      .navbar__menu-btn-enter-active::before, .navbar__menu-btn-enter-active::after {
        width: 0;
        transition: all 0.5s; }
    .navbar__menu-btn-exit, .navbar__menu-btn-enter-done {
      width: 0; }
      .navbar__menu-btn-exit::before, .navbar__menu-btn-exit::after, .navbar__menu-btn-enter-done::before, .navbar__menu-btn-enter-done::after {
        width: 0; }
    .navbar__menu-btn-exit-active {
      width: 3rem;
      transition: all 0.5s; }
      .navbar__menu-btn-exit-active::before {
        width: 2rem;
        transition: all 0.5s; }
      .navbar__menu-btn-exit-active::after {
        width: 4rem;
        transition: all 0.5s; }
  .navbar__menu-btn-box {
    height: 100%;
    width: 4rem;
    background: transparent;
    align-items: center;
    display: none; }
    @media (max-width: 56.25em) {
      .navbar__menu-btn-box {
        display: flex; } }
    @media (max-width: 56.25em) {
      .navbar__menu-btn-box--profile {
        display: none; } }
  .navbar__profile-btn-box {
    display: none; }
    @media (max-width: 56.25em) {
      .navbar__profile-btn-box {
        display: flex; } }
  .navbar__logo {
    display: flex;
    align-items: center; }
    .navbar__logo img {
      height: 4rem; }
    @media (max-width: 56.25em) {
      .navbar__logo {
        display: none; } }
  .navbar__search-box {
    display: flex;
    align-items: center;
    grid-row: row-navbar-start / row-navbar-end;
    grid-column: col-navbar-center-start / col-navbar-center-end; }
    @media (max-width: 56.25em) {
      .navbar__search-box {
        grid-column: col-navbar-right-start / col-navbar-right-end; } }
  .navbar__search-form {
    width: 100%;
    position: relative;
    height: 3.7rem; }
  .navbar__search-input {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.6rem;
    width: 100%;
    border-radius: 100rem;
    height: 100%;
    align-items: center;
    padding-left: 1.5rem; }
    .theme-light .navbar__search-input {
      color: #141716; }
    .theme-dark .navbar__search-input {
      color: #fff; }
    .theme-light .navbar__search-input {
      background-color: rgba(0, 146, 219, 0.65); }
    .theme-dark .navbar__search-input {
      background-color: rgba(0, 146, 219, 0.45); }
    .navbar__search-input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .navbar__search-input:-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .navbar__search-input::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
    .navbar__search-input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */ }
      .theme-light .navbar__search-input::-webkit-input-placeholder {
        color: #383838; }
      .theme-light .navbar__search-input:-ms-input-placeholder {
        color: #383838; }
      .theme-light .navbar__search-input::-ms-input-placeholder {
        color: #383838; }
      .theme-light .navbar__search-input::placeholder {
        color: #383838; }
      .theme-dark .navbar__search-input::-webkit-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .navbar__search-input:-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .navbar__search-input::-ms-input-placeholder {
        color: #d0d0d0; }
      .theme-dark .navbar__search-input::placeholder {
        color: #d0d0d0; }
    .navbar__search-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */ }
      .theme-light .navbar__search-input:-ms-input-placeholder {
        color: #383838; }
      .theme-dark .navbar__search-input:-ms-input-placeholder {
        color: #d0d0d0; }
    .navbar__search-input::-ms-input-placeholder {
      /* Microsoft Edge */ }
      .theme-light .navbar__search-input::-ms-input-placeholder {
        color: #383838; }
      .theme-dark .navbar__search-input::-ms-input-placeholder {
        color: #d0d0d0; }
    .navbar__search-input-enter {
      opacity: 0;
      visibility: hidden;
      width: 0; }
    .navbar__search-input-enter-active {
      opacity: 1;
      visibility: visible;
      width: 100%;
      transition: all 0.5s ease-in-out; }
    .navbar__search-input-exit {
      opacity: 1;
      visibility: visible;
      width: 100%; }
    .navbar__search-input-exit-active {
      opacity: 0;
      visibility: hidden;
      width: 0;
      transition: all 0.5s ease-in-out; }
  .navbar__search-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    height: 3.7rem;
    width: 3.7rem;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.5s;
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    @media not all and (pointer: coarse) {
      .theme-light .navbar__search-btn:hover {
        background-color: rgba(0, 146, 219, 0.65); }
      .theme-dark .navbar__search-btn:hover {
        background-color: rgba(0, 146, 219, 0.45); } }
    .navbar__search-btn--active {
      /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
      @media not all and (pointer: coarse) {
        .navbar__search-btn--active:hover {
          background-color: transparent !important; } }
  .navbar__search-icon {
    display: flex;
    font-size: 2.5rem; }
    .theme-light .navbar__search-icon {
      color: #141716; }
    .theme-dark .navbar__search-icon {
      color: #fff; }
  .navbar__btn-box {
    grid-column: col-navbar-right-start / col-navbar-right-end;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    @media (max-width: 56.25em) {
      .navbar__btn-box {
        display: none; } }
    .navbar__btn-box > *:not(:first-child) {
      margin-left: 4rem; }
    .navbar__btn-box--item-enter {
      visibility: hidden;
      opacity: 0; }
    .navbar__btn-box--item-enter-active {
      visibility: visible;
      opacity: 1;
      transition: width 0.2s, opacity 0.2s 0.2s, visibility 0.2s 0.2s; }
    .navbar__btn-box--item-exit {
      visibility: visible;
      opacity: 1; }
    .navbar__btn-box--item-exit-active {
      visibility: hidden;
      opacity: 0;
      transition: width 0.2s 0.2s, opacity 0.2s, visibility 0.2s; }

.settings {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: [main-start] 1fr [main-end footer-start] 8rem [footer-end]; }
  .settings__main {
    grid-row: main-start / main-end;
    display: flex;
    flex-direction: column; }
    .settings__main > * {
      margin-bottom: 2rem; }
  .settings__footer {
    grid-row: footer-start / footer-end;
    position: fixed;
    width: 100%;
    height: 8rem;
    bottom: 0;
    left: 0;
    z-index: 500; }
  .settings__menu-box {
    width: 100%;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column; }
    .settings__menu-box > *:not(:last-child) {
      margin-bottom: 2rem; }
  .settings__menu-item {
    position: relative;
    padding: 0 2rem;
    display: flex;
    align-self: flex-start;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap; }
    .settings__menu-item--border-bottom::after {
      left: 0;
      bottom: -2px;
      content: "";
      position: absolute;
      width: 100%;
      height: 2px; }
      .theme-light .settings__menu-item--border-bottom::after {
        background-image: linear-gradient(to bottom, #d0d0d0, transparent); }
      .theme-dark .settings__menu-item--border-bottom::after {
        background-image: linear-gradient(to bottom, #383838, transparent); }
  .settings__menu-header {
    font-weight: 800;
    font-size: 2.4rem;
    display: flex;
    height: 8rem;
    align-items: center;
    max-width: 40rem;
    width: 100%; }
    .theme-light .settings__menu-header {
      color: #141716; }
    .theme-dark .settings__menu-header {
      color: #fff; }
    .settings__menu-header > *:not(:last-child) {
      margin-right: 2rem; }
  .settings__menu-footer {
    width: 100%;
    max-width: 40rem; }

.sidebar {
  width: 100%;
  padding: 2rem 0;
  overflow-x: auto;
  height: 100%;
  display: grid;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  position: relative;
  grid-template-rows: [row-medium-start] minmax(-webkit-min-content, 1fr) [row-medium-end row-big-start] minmax(-webkit-min-content, 1.4fr) [row-big-end row-small-start-1] minmax(-webkit-min-content, 0.5fr) [row-small-end-1 row-small-start-2] minmax(-webkit-min-content, 0.5fr) [row-small-end-2 row-grid-end];
  grid-template-rows: [row-medium-start] minmax(min-content, 1fr) [row-medium-end row-big-start] minmax(min-content, 1.4fr) [row-big-end row-small-start-1] minmax(min-content, 0.5fr) [row-small-end-1 row-small-start-2] minmax(min-content, 0.5fr) [row-small-end-2 row-grid-end];
  grid-template-columns: [col-sidebar-start] 1fr [col-sidebar-end];
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */
  /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
  .sidebar__menu {
    grid-row: row-medium-start / row-medium-end;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .sidebar__menu > *:not(:last-child) {
      margin-bottom: 3px; }
  .sidebar__menu-item-box {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .sidebar__menu-loading {
    width: 100%;
    padding: 0.3rem 1.5rem; }
  .sidebar__menu-item {
    text-decoration: none;
    position: relative;
    padding: 0.3rem 1.5rem;
    background-color: transparent;
    border-radius: 100rem;
    display: flex;
    align-items: center;
    transition: background-color 0.2s;
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    .sidebar__menu-item-enter {
      min-width: 0; }
    .sidebar__menu-item-enter-active {
      z-index: 10;
      min-width: 100%;
      transition: min-width 0.2s ease-out; }
      .theme-light .sidebar__menu-item-enter-active {
        background-color: #0092db; }
      .theme-dark .sidebar__menu-item-enter-active {
        background-color: #0092db; }
    .sidebar__menu-item-exit, .sidebar__menu-item-enter-done {
      z-index: 10;
      min-width: 100%; }
      .theme-light .sidebar__menu-item-exit, .theme-light .sidebar__menu-item-enter-done {
        background-color: #0092db; }
      .theme-dark .sidebar__menu-item-exit, .theme-dark .sidebar__menu-item-enter-done {
        background-color: #0092db; }
    .sidebar__menu-item-exit-active {
      transition: min-width 0.2s ease-out 0.2s;
      min-width: 0; }
    .sidebar__menu-item > *:not(:last-child) {
      margin-right: 1rem; }
    @media not all and (pointer: coarse) {
      .theme-light .sidebar__menu-item:hover {
        background-color: #0092db; }
      .theme-dark .sidebar__menu-item:hover {
        background-color: #0092db; } }
  .theme-light .sidebar__menu-item--active .sidebar__menu-icon svg {
    fill: #0092db; }
  .theme-dark .sidebar__menu-item--active .sidebar__menu-icon svg {
    fill: #0092db; }
  .theme-light .sidebar__menu-item--active .sidebar__menu-icon {
    color: #0092db; }
  .theme-dark .sidebar__menu-item--active .sidebar__menu-icon {
    color: #0092db; }
  .theme-light .sidebar__menu-item--active .sidebar__menu-text {
    color: #0092db; }
  .theme-dark .sidebar__menu-item--active .sidebar__menu-text {
    color: #0092db; }
  .sidebar__menu-icon {
    display: flex;
    align-items: center;
    transition: color 0.2s; }
    .theme-light .sidebar__menu-icon {
      color: #141716; }
    .theme-dark .sidebar__menu-icon {
      color: #fff; }
    .sidebar__menu-icon svg {
      width: 1.7rem;
      transition: fill 0.2s; }
      .theme-light .sidebar__menu-icon svg {
        fill: #141716; }
      .theme-dark .sidebar__menu-icon svg {
        fill: #fff; }
  .sidebar__menu-item-enter-done > .sidebar__menu-icon svg,
  .sidebar__menu-item-enter-active > .sidebar__menu-icon svg,
  .sidebar__menu-item-exit > .sidebar__menu-icon svg {
    fill: #fff; }
  @media not all and (pointer: coarse) {
    .sidebar__menu-item:hover .sidebar__menu-icon svg {
      fill: #fff; }
    .sidebar__menu-item:hover .sidebar__menu-icon {
      color: #fff; } }
  .sidebar__menu-item-expand-box {
    z-index: 5;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem; }
    .theme-light .sidebar__menu-item-expand-box {
      background-color: #d2dcdf; }
    .theme-dark .sidebar__menu-item-expand-box {
      background-color: #141716; }
    .sidebar__menu-item-expand-box-enter {
      opacity: 0;
      visibility: hidden; }
    .sidebar__menu-item-expand-box-enter-active {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease-in-out 0.2s; }
    .sidebar__menu-item-expand-box-exit {
      opacity: 1;
      visibility: visible; }
    .sidebar__menu-item-expand-box-exit-active {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out; }
    .sidebar__menu-item-expand-box::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 33%;
      height: 2px;
      transform: translateX(-50%); }
      .theme-light .sidebar__menu-item-expand-box::after {
        background-color: #0092db; }
      .theme-dark .sidebar__menu-item-expand-box::after {
        background-color: #0092db; }
  .sidebar__menu-options-box {
    display: grid;
    padding: 3rem 2rem 2rem 2rem;
    grid-template-rows: repeat(5, -webkit-min-content);
    grid-template-rows: repeat(5, min-content);
    grid-template-columns: 1fr;
    grid-row-gap: 0.7rem;
    row-gap: 0.7rem; }
  .sidebar__menu-options-heading {
    font-weight: 300;
    font-size: 1.2rem; }
    .theme-light .sidebar__menu-options-heading {
      color: #383838; }
    .theme-dark .sidebar__menu-options-heading {
      color: #d0d0d0; }
  .sidebar__menu-options-language {
    display: flex;
    flex-direction: column; }
    .sidebar__menu-options-language > *:not(:last-child) {
      margin-bottom: 0.7rem; }
  .sidebar__menu-options-theme {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
  .sidebar__menu-categories-box {
    padding: 3rem 0.7rem 2rem 0.7rem;
    grid-gap: 1rem;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    display: flex; }
    .sidebar__menu-categories-box--error {
      height: 10rem;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 500; }
      .theme-light .sidebar__menu-categories-box--error {
        color: #d00000; }
      .theme-dark .sidebar__menu-categories-box--error {
        color: #d00000; }
  .sidebar__menu-categories-btn {
    font-size: 1.5rem; }
    .theme-light .sidebar__menu-categories-btn {
      color: #0092db; }
    .theme-dark .sidebar__menu-categories-btn {
      color: #0092db; }
  .sidebar__menu-categories {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 2px;
    gap: 2px; }
  .sidebar__menu-category {
    position: relative;
    display: flex;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    font-size: 1.3rem;
    line-height: 1.3rem;
    font-weight: 500;
    border-radius: 100rem;
    padding: 2px 3px;
    overflow: hidden; }
    .theme-light .sidebar__menu-category {
      color: #383838; }
    .theme-dark .sidebar__menu-category {
      color: #d0d0d0; }
    .theme-light .sidebar__menu-category--active {
      border-color: #1db954; }
    .theme-dark .sidebar__menu-category--active {
      border-color: #1db954; }
    .theme-light .sidebar__menu-category--inactive {
      border-color: #0092db; }
    .theme-dark .sidebar__menu-category--inactive {
      border-color: #0092db; }
  .sidebar__menu-categories-btn {
    font-size: 2rem;
    display: flex;
    background: transparent;
    padding: 0.5rem; }
    .theme-light .sidebar__menu-categories-btn {
      color: #0092db; }
    .theme-dark .sidebar__menu-categories-btn {
      color: #0092db; }
  .sidebar__menu-chosen-category {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    border-radius: 100rem; }
    .theme-light .sidebar__menu-chosen-category--add {
      background-color: #1db954; }
    .theme-dark .sidebar__menu-chosen-category--add {
      background-color: #1db954; }
    .theme-light .sidebar__menu-chosen-category--remove {
      background-color: #0092db; }
    .theme-dark .sidebar__menu-chosen-category--remove {
      background-color: #0092db; }
    .sidebar__menu-chosen-category-enter {
      transform: translateX(100%);
      opacity: 0;
      visibility: hidden; }
    .sidebar__menu-chosen-category-enter-active {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
      transition: all 0.4s; }
    .sidebar__menu-chosen-category-exit {
      transform: translateX(0);
      opacity: 1;
      visibility: visible; }
    .sidebar__menu-chosen-category-exit-active {
      transform: translateX(100%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s; }
  .sidebar__menu-text {
    font-weight: 600;
    font-size: 1.6rem;
    transition: color 0.2s; }
    .theme-light .sidebar__menu-text {
      color: #141716; }
    .theme-dark .sidebar__menu-text {
      color: #fff; }
  .sidebar__menu-item-enter-done > .sidebar__menu-text,
  .sidebar__menu-item-enter-active > .sidebar__menu-text,
  .sidebar__menu-item-exit > .sidebar__menu-text {
    color: #fff; }
  @media not all and (pointer: coarse) {
    .sidebar__menu-item:hover .sidebar__menu-text {
      color: #fff; } }
  .sidebar__footer {
    grid-row: row-small-start-2 / row-small-end-2;
    display: flex;
    flex-direction: column;
    justify-self: start;
    margin-left: 1.5rem;
    margin-bottom: 4rem; }
    .sidebar__footer--secondary {
      font-weight: 400;
      font-size: 1.2rem; }
      .theme-light .sidebar__footer--secondary {
        color: #383838; }
      .theme-dark .sidebar__footer--secondary {
        color: #d0d0d0; }
    .sidebar__footer--primary {
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
      font-size: 1.3rem; }
      .theme-light .sidebar__footer--primary {
        color: #141716; }
      .theme-dark .sidebar__footer--primary {
        color: #fff; }
  .sidebar__advertisement-box {
    border-radius: 1.5rem;
    overflow: hidden; }
    .sidebar__advertisement-box--small {
      grid-row: row-medium-start / row-medium-end; }
    .sidebar__advertisement-box--big {
      grid-row: row-big-start / row-big-end; }
  .sidebar__no-ads-message {
    text-align: center;
    padding: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    color: #fff; }
    .theme-light .sidebar__no-ads-message {
      background-color: rgba(0, 146, 219, 0.65); }
    .theme-dark .sidebar__no-ads-message {
      background-color: rgba(0, 146, 219, 0.45); }
  .sidebar__footer-btn {
    text-decoration: none; }
  .sidebar__social-media-box {
    padding-left: 1rem;
    grid-row: row-small-start-1 / row-small-end-1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
    align-items: flex-startW;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem; }
  .sidebar__social-media-header {
    font-size: 1.6rem;
    grid-column: 1 / -1; }
    .sidebar__social-media-header--primary {
      line-height: 100%;
      font-weight: 500; }
      .theme-light .sidebar__social-media-header--primary {
        color: #141716; }
      .theme-dark .sidebar__social-media-header--primary {
        color: #fff; }
    .sidebar__social-media-header--secondary {
      line-height: 100%;
      font-weight: 400; }
      .theme-light .sidebar__social-media-header--secondary {
        color: #141716; }
      .theme-dark .sidebar__social-media-header--secondary {
        color: #fff; }
  .sidebar__social-media-icon-box {
    display: flex;
    align-items: center; }
  .sidebar__social-media-icon {
    font-size: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    .theme-light .sidebar__social-media-icon {
      color: #141716; }
    .theme-dark .sidebar__social-media-icon {
      color: #fff; }
    .sidebar__social-media-icon--number {
      font-size: 1.2rem;
      font-weight: 400; }
      .theme-light .sidebar__social-media-icon--number {
        color: #383838; }
      .theme-dark .sidebar__social-media-icon--number {
        color: #d0d0d0; }
    .sidebar__social-media-icon > *:not(:last-child) {
      margin-right: 1.3rem; }
    @media not all and (pointer: coarse) {
      .theme-light .sidebar__social-media-icon:hover {
        color: #0092db; }
      .theme-dark .sidebar__social-media-icon:hover {
        color: #0092db; }
      .theme-light .sidebar__social-media-icon:hover .sidebar__social-media-icon--number {
        color: #0092db; }
      .theme-dark .sidebar__social-media-icon:hover .sidebar__social-media-icon--number {
        color: #0092db; } }

.text-header {
  background-color: #0092db;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 4rem 4rem 8rem 4rem;
  overflow: hidden; }
  .text-header--bottombar {
    min-height: calc(100vh - 8rem); }
  .text-header__heading {
    display: flex;
    flex-direction: column;
    max-width: 70rem;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-weight: 600; }
    .text-header__heading > *:not(:last-child) {
      margin-bottom: 2rem; }
    .text-header__heading--primary {
      color: #fff;
      font-size: 4.5rem;
      -webkit-animation: slideInRight 0.6s 0.6s;
              animation: slideInRight 0.6s 0.6s;
      -webkit-animation-fill-mode: backwards;
              animation-fill-mode: backwards; }
      @media (max-width: 31.25em) {
        .text-header__heading--primary {
          font-size: 3.5rem; } }
    .text-header__heading--secondary {
      color: #d0d0d0;
      font-size: 3.5rem;
      -webkit-animation: slideInLeft 0.3s 0.3s;
              animation: slideInLeft 0.3s 0.3s;
      -webkit-animation-fill-mode: backwards;
              animation-fill-mode: backwards; }
      @media (max-width: 31.25em) {
        .text-header__heading--secondary {
          font-size: 2.5rem; } }
  .text-header__scroll-down-btn {
    position: absolute;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3.5rem;
    color: #fff;
    display: flex;
    background: transparent; }

.about {
  position: relative;
  padding-bottom: 8rem; }
  .about__description {
    position: relative;
    z-index: 30;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    padding: 10rem 4rem 25rem 4rem;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 15rem), 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 15rem), 0 100%); }
    .theme-light .about__description {
      background-color: #fff; }
    .theme-dark .about__description {
      background-color: #08090a; }
    @media (max-width: 43.75em) {
      .about__description {
        padding: 10rem 4rem 17rem 4rem;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7rem), 0 100%);
                clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7rem), 0 100%); } }
    @media (max-width: 22rem) {
      .about__description {
        padding: 8rem 4rem 11rem 4rem;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 3rem), 0 100%);
                clip-path: polygon(0 0, 100% 0, 100% calc(100% - 3rem), 0 100%); } }
  .about__description-paragraph {
    font-size: 1.8rem;
    font-weight: 400;
    max-width: 80rem;
    align-self: center;
    line-height: 250%; }
    .theme-light .about__description-paragraph {
      color: #383838; }
    .theme-dark .about__description-paragraph {
      color: #d0d0d0; }
    .about__description-paragraph--bold {
      font-weight: 600; }
    .about__description-paragraph:not(:last-child) {
      margin-bottom: 2rem; }
  .about__values {
    position: relative;
    z-index: 20;
    background-image: linear-gradient(to right bottom, #009eed 0 20%, #006ea5 80%);
    display: flex;
    flex-direction: column;
    margin-top: -15rem;
    padding: 25rem 3rem;
    -webkit-clip-path: polygon(0 15rem, 100% 0, 100% calc(100% - 15rem), 0 100%);
            clip-path: polygon(0 15rem, 100% 0, 100% calc(100% - 15rem), 0 100%); }
    @media (max-width: 43.75em) {
      .about__values {
        margin-top: -7rem; } }
    @media (max-width: 22rem) {
      .about__values {
        margin-top: -3rem; } }
    @media (max-width: 43.75em) {
      .about__values {
        padding: 17rem 3rem;
        -webkit-clip-path: polygon(0 7rem, 100% 0, 100% calc(100% - 7rem), 0 100%);
                clip-path: polygon(0 7rem, 100% 0, 100% calc(100% - 7rem), 0 100%); } }
    @media (max-width: 22rem) {
      .about__values {
        padding: 11rem 3rem;
        -webkit-clip-path: polygon(0 3rem, 100% 0, 100% calc(100% - 3rem), 0 100%);
                clip-path: polygon(0 3rem, 100% 0, 100% calc(100% - 3rem), 0 100%); } }
  .about__values-heading {
    text-align: center;
    color: #fff;
    width: 100%;
    max-width: 110rem;
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 8rem;
    align-self: center; }
    @media (max-width: 43.75em) {
      .about__values-heading {
        width: auto; } }
    @media (max-width: 31.25em) {
      .about__values-heading {
        font-size: 2.7rem; } }
    .about__values-heading--strike {
      position: relative;
      display: inline-block; }
      .about__values-heading--strike::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #fff; }
        @media (max-width: 31.25em) {
          .about__values-heading--strike::before {
            height: 2px; } }
      .about__values-heading--strike::after {
        content: "Sabores";
        position: absolute;
        font-size: 6rem;
        left: 0.5rem;
        top: 20%;
        font-family: "Yellowtail", sans-serif;
        color: #040b1d; }
        @media (max-width: 31.25em) {
          .about__values-heading--strike::after {
            font-size: 3.4rem; } }
  .about__values-content {
    width: 100%;
    align-self: center;
    max-width: 100rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(-webkit-min-content, 30rem));
    grid-template-columns: repeat(auto-fit, minmax(min-content, 30rem));
    justify-content: center;
    grid-gap: 5rem;
    gap: 5rem; }
  .about__values-item {
    background-color: #fff;
    opacity: 0.7;
    border-radius: 4px;
    box-shadow: 0 1.5rem 4rem rgba(4, 11, 29, 0.15);
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    .about__values-item--icon {
      color: #0092db;
      font-size: 8rem;
      margin-bottom: 3rem; }
    .about__values-item--heading {
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.6rem;
      color: #383838;
      margin-bottom: 2rem; }
    .about__values-item--text {
      text-align: center;
      font-size: 1.6rem;
      font-weight: 400;
      color: #383838; }
    @media not all and (pointer: coarse) {
      .about__values-item:hover {
        transform: translateY(-1rem); } }
  .about__members {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25rem 4rem 10rem;
    margin-top: -15rem; }
    .theme-light .about__members {
      background-color: #fff; }
    .theme-dark .about__members {
      background-color: #08090a; }
    @media (max-width: 56.25em) {
      .about__members {
        padding: 25rem 3rem 10rem 3rem; } }
    @media (max-width: 43.75em) {
      .about__members {
        padding: 17rem 4rem 10rem;
        margin-top: -7rem; } }
    @media (max-width: 22rem) {
      .about__members {
        padding: 11rem 3rem 8rem 3rem;
        margin-top: -3rem; } }
  .about__members-grid {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-gap: 10rem;
    gap: 10rem;
    grid-template-columns: repeat(auto-fit, minmax(-webkit-min-content, 35rem));
    grid-template-columns: repeat(auto-fit, minmax(min-content, 35rem)); }
  .about__bottombar {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    height: 8rem;
    width: 100%; }

.categories {
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 2rem;
  gap: 2rem; }
  .categories__go-btn {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    padding: 0.4rem 2rem;
    border-radius: 100rem;
    transition: all 0.2s;
    background: transparent;
    color: #0092db;
    border: 2px solid #0092db;
    font-size: 1.6rem;
    font-weight: 500;
    /*
    This disables hover on firefox,
    but it fixes hover related problems in
    mobile devices, so it's worth it
  */ }
    @media not all and (pointer: coarse) {
      .categories__go-btn:hover {
        background-color: #0092db;
        color: #fff; } }
  .categories__box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
  .categories__item {
    display: flex;
    cursor: pointer;
    font-size: 1.5rem;
    position: relative;
    font-weight: 500;
    border-radius: 100rem;
    padding: 0 0.5rem;
    border-width: 1px;
    overflow: hidden;
    border-style: solid; }
    .theme-light .categories__item {
      color: #383838; }
    .theme-dark .categories__item {
      color: #d0d0d0; }
    .theme-light .categories__item--active {
      border-color: #1db954; }
    .theme-dark .categories__item--active {
      border-color: #1db954; }
    .theme-light .categories__item--inactive {
      border-color: #0092db; }
    .theme-dark .categories__item--inactive {
      border-color: #0092db; }
  .categories__chosen-item {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    width: 100%;
    height: 100%;
    border-radius: 100rem; }
    .theme-light .categories__chosen-item--add {
      background-color: #1db954; }
    .theme-dark .categories__chosen-item--add {
      background-color: #1db954; }
    .theme-light .categories__chosen-item--remove {
      background-color: #0092db; }
    .theme-dark .categories__chosen-item--remove {
      background-color: #0092db; }
    .categories__chosen-item-enter {
      transform: translateX(100%);
      opacity: 0;
      visibility: hidden; }
    .categories__chosen-item-enter-active {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
      transition: all 0.4s; }
    .categories__chosen-item-exit {
      transform: translateX(0);
      opacity: 1;
      visibility: visible; }
    .categories__chosen-item-exit-active {
      transform: translateX(100%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s; }

.logout__modal {
  display: flex;
  flex-direction: column; }
  .logout__modal > * {
    margin-bottom: 2rem; }

.logout__modal-header {
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
  font-weight: 600; }
  .theme-light .logout__modal-header {
    background-color: #0092db; }
  .theme-dark .logout__modal-header {
    background-color: #0092db; }

.preferences__heading {
  font-weight: 800;
  font-size: 2.4rem;
  max-width: 40rem;
  width: 100%; }
  .theme-light .preferences__heading {
    color: #141716; }
  .theme-dark .preferences__heading {
    color: #fff; }
  .theme-light .preferences__heading {
    color: #141716; }
  .theme-dark .preferences__heading {
    color: #fff; }

.preferences__language {
  padding-left: 1rem;
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column; }
  .preferences__language > *:not(:last-child) {
    margin-bottom: 1.5rem; }

.preferences__theme {
  display: grid;
  padding-left: 1rem;
  width: 100%;
  max-width: 40rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem; }

.post-preview {
  display: flex;
  justify-content: center;
  padding: 2rem 0; }
  @media (max-width: 31.25em) {
    .post-preview {
      padding: 0; } }

.users {
  display: grid;
  grid-row-gap: 3rem;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 2rem 0; }

